import Button from "@mui/material/Button";
import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  StripePaymentElementOptions,
  StripeAddressElementOptions,
} from "@stripe/stripe-js";
import { Fragment } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { completeSubscription } from "./thunks";
import { createSubscriptionState } from "./create-subscription-slice";

// interface SubscriptionDetailsProps {
//     stripePromise: Promise<Stripe | null>
// }
const SubscriptionDetails = () => {
  const elements = useElements();
  const stripe = useStripe();
  const dispatch = useAppDispatch();
  const state = useAppSelector(createSubscriptionState);

  const paymentOptions: StripePaymentElementOptions = {};

  let addressOptions: StripeAddressElementOptions = {
    mode: "billing",

    display: { name: "full" },
    autocomplete: {
      mode: "automatic",
    },
    allowedCountries: ["AU"],
  };

  const handleCreate = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    let confirmSetupOptions = {
      elements: elements,
      confirmParams: {
        return_url: "http://localhost:5173/subscriptions/complete",
      },
    };

    elements?.submit();

    let result = await stripe?.confirmSetup(confirmSetupOptions);

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      dispatch(
        completeSubscription({
          setupIntentID: state.setupIntentID!,
        })
      );
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <Fragment>
      <form>
        <h1>Billing Details</h1>

        <AddressElement options={addressOptions} />
        <PaymentElement options={paymentOptions} />
        <Button onClick={handleCreate}>Create</Button>
      </form>
    </Fragment>
  );
};

export default SubscriptionDetails;
