import { FC } from "react";
import Page from "../../components/page";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Outlet, useNavigate } from "react-router-dom";
import { withAuthenticationRequired } from "react-oidc-context";
import RedirectToLogin from "../../components/redirect-to-login";
const SettingsPage: FC = () => {
  const navigate = useNavigate();
  function handleMyDetailsClick(): void {
    navigate("my-details");
  }

  return (
    <Page title="Settings">
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <List>
            <ListItem key="settings" disablePadding>
              <ListItemButton onClick={handleMyDetailsClick}>
                <ListItemIcon>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText primary="My details" />
              </ListItemButton>
            </ListItem>
            <ListItem key="licencee" disablePadding>
              <ListItemButton onClick={() => navigate("account")}>
                <ListItemIcon>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText primary="Account" />
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={9}>
          <Outlet></Outlet>
        </Grid>
      </Grid>
    </Page>
  );
};

export default withAuthenticationRequired(SettingsPage, {
  OnRedirecting: () => <RedirectToLogin />,
  signinRedirectArgs: { redirect_uri: window.location.href },
});
