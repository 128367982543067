import { FC, useEffect, useState } from "react";
import Page from "../../components/page";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { WorkspaceStatus, workspaceState } from "./workspace-slice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CircularProgress, Grid } from "@mui/material";
import {
  completeTask,
  getWorkspace,
  inviteSellerSolicitor,
  newOffer,
  sendToBuyerForSigning,
  sendToSellerForSigning,
  shareWithSeller,
} from "./thunks";
import AgentWorkspaceView from "./components/agent-view";
import { NewOfferResponse, getOffer } from "../offer-stepper/thunks";
import { Contract, WorkspaceRoleEnum, WorkspaceSummary } from "../../generated";
import { getAgentAppointment } from "../agent-appointment/thunks";
import SolicitorView from "./components/solicitor-view";
import navigateToWorkflowStep from "./workspace-navigator";
import { withAuthenticationRequired } from "react-oidc-context";
import RedirectToLogin from "../../components/redirect-to-login";

const Workspace: FC = () => {
  const state = useAppSelector(workspaceState);
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [id] = useState(parseInt(params.workspaceId!));
  const { workspaceId } = params;

  useEffect(() => {
    if (state.status == WorkspaceStatus.ready) {
      navigateToWorkflowStep(
        state.workspace.workspace?.role!,
        state.workflow!,
        navigate,
        location
      );
    }
    if (state.status == WorkspaceStatus.workspaceDirty) {
      dispatch(getWorkspace(parseInt(workspaceId!)));
    }
  }, [dispatch, state.status]);

  useEffect(() => {
    dispatch(getWorkspace(id));
  }, [id]);

  function handleOnOfferCreate(): void {
    dispatch(newOffer(parseInt(workspaceId!))).then((s) => {
      const offerId = (s.payload as NewOfferResponse).offerId;
      dispatch(
        getOffer({
          workspaceId: state.workspace.workspace?.id!,
          offerId: offerId,
        })
      ).then(() => {
        navigate(`offers/${offerId}`);
      });
    });
  }

  function onOfferEdit(offer?: Contract | undefined): void {
    dispatch(
      getOffer({ workspaceId: parseInt(workspaceId!), offerId: offer!.id! })
    );
    navigate(`offers/${offer!.id!}`);
  }

  function onEditAgentAppointment(workspace: WorkspaceSummary): void {
    dispatch(getAgentAppointment(workspace.workspace?.id!)).then(() => {
      navigate("agent-appointment");
    });
  }
  function onEditContract(): void {
    // dispatch(getContract({workspaceId:workspaceId})).then(() => {
    //   navigate("contract");
    // });
  }

  function handleInviteSellerSolicitor(emailAddress: string): void {
    dispatch(
      inviteSellerSolicitor({
        workspaceId: state.workspace.workspace!.id,
        emailAddress: emailAddress,
      })
    );
  }

  // function handleInviteBuyerSolicitor(
  //   offerId: number,
  //   emailAddress: string
  // ): void {
  //   dispatch(
  //     inviteBuyerSolicitor({
  //       workspaceId: state.workspace.workspace!.id,
  //       offerId: offerId,
  //       emailAddress: emailAddress,
  //     })
  //   );
  // }
  function handleSendToSeller(offerId: number): void {
    dispatch(
      sendToSellerForSigning({
        workspaceId: state.workspace.workspace!.id!,
        offerId: offerId,
      })
    );
  }

  function handleSendToBuyer(offerId: number): void {
    dispatch(
      sendToBuyerForSigning({
        workspaceId: state.workspace.workspace!.id!,
        offerId: offerId,
      })
    );
  }

  function handleShareWithSeller(offerId: number): void {
    dispatch(
      shareWithSeller({
        workspaceId: state.workspace.workspace!.id!,
        offerId: offerId,
      })
    );
  }

  return (
    <Page
      title={
        state.status == WorkspaceStatus.ready
          ? state.workspace.property?.streetAddress1
          : ""
      }
      showBackNavigation={true}
    >
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {state.status == WorkspaceStatus.loading ||
        state.status == WorkspaceStatus.initial ? (
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <CircularProgress size={200} sx={{ color: "#8F1219" }} />
          </Grid>
        ) : null}

        {state.status == WorkspaceStatus.ready ||
        state.status == WorkspaceStatus.workspaceDirty ? (
          <Grid item xs={12}>
            {(state.workspace.workspace?.role ==
              WorkspaceRoleEnum.SellerAgent ||
              state.workspace.workspace?.role == WorkspaceRoleEnum.Seller ||
              state.workspace.workspace?.role == WorkspaceRoleEnum.Buyer) && (
              <AgentWorkspaceView
                state={state}
                onOfferEdit={onOfferEdit}
                onSendToSeller={handleSendToSeller}
                onSendToBuyer={handleSendToBuyer}
                onOfferCreate={handleOnOfferCreate}
                onEditAgentAppointment={onEditAgentAppointment}
                onEditContract={onEditContract}
                onInviteSellerSolicitor={handleInviteSellerSolicitor}
                onInviteBuyerSolicitor={handleInviteSellerSolicitor}
                onShareWithSeller={handleShareWithSeller}
                onSendAgentAppointment={() => {
                  dispatch(
                    completeTask({
                      workspaceId: id,
                      taskName: "send-agent-appointment-for-signing",
                    })
                  );
                }}
                onOfferClicked={(offerId: number) => {
                  navigate("offers/" + offerId);
                }}
                onDocumentView={(documentName) => {
                  navigate(`document/${documentName}`);
                }}
                onWorkflowChanged={() => {}}
                onTaskCompleted={() => null}
                onCompleteAgentAppointment={() => null}
                onNewOffer={(s) => {
                  dispatch(newOffer(s.workspace?.id!)).then((d) => {
                    const f = d.payload as NewOfferResponse;
                    navigate("offers/" + f.offerId);
                  });
                }}
              />
            )}
            {/* {state.workspace.workspace?.role == WorkspaceRoleEnum.Seller && (
              <SellerWorkspaceView
                state={state}
                onOfferClicked={function (_: number): void {
                  throw new Error("Function not implemented.");
                }}
                onDocumentView={function (_: string): void {
                  throw new Error("Function not implemented.");
                }}
                onInviteSellerSolicitor={handleInviteSellerSolicitor}
              />
            )} */}

            {/* {state.workspace.workspace?.role == WorkspaceRoleEnum.Buyer && (
              <BuyerWorkspaceView
                state={state}
                onInviteBuyerSolicitor={handleInviteBuyerSolicitor}
                onOfferClicked={function (_: number): void {
                  throw new Error("Function not implemented.");
                }}
                onDocumentView={function (_: string): void {
                  throw new Error("Function not implemented.");
                }}
              />
            )} */}

            {state.workspace.workspace?.role ==
              WorkspaceRoleEnum.SellerSolicitor && (
              <SolicitorView state={state} onEditContract={onEditContract} />
            )}

            {state.workspace.workspace?.role ==
              WorkspaceRoleEnum.BuyerSolicitor && (
              <SolicitorView state={state} onEditContract={onEditContract} />
            )}
          </Grid>
        ) : null}
      </Grid>
    </Page>
  );
};

export default withAuthenticationRequired(Workspace, {
  OnRedirecting: () => {
    console.log(window.location.href);
    return <RedirectToLogin />;
  },
  signinRedirectArgs: { redirect_uri: window.location.href },
});
