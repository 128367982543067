import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { createOffer, getOffers, getWorkflow, getWorkspace } from "./thunks";
import { OfferDetail, Workflow, WorkspaceSummary } from "../../generated";

export enum OffersStatus {
  initial,
  loading,
  ready,
}
export interface OffersState {
  status: OffersStatus;
  offers: Array<OfferDetail>;
  workspace: WorkspaceSummary;
  workflow: Workflow;
  newOfferId?: number;
}
const initialState: OffersState = {
  status: OffersStatus.initial,
  offers: [],
  workflow: {
    state: "",
    subState: "",
    label: "",
    appointAgent: "",
    contractPreparation: "",
    offers: [],
  },
  workspace: { property: {}, workspace: { id: 0, role: "sellerAgent" } },
};
export const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getOffersReducer(builder);
    getWorkspaceReducer(builder);
    getWorkflowReducer(builder);
    createOfferReducer(builder);
  },
});

function getOffersReducer(builder: ActionReducerMapBuilder<OffersState>) {
  builder.addCase(getOffers.fulfilled, (state, action) => {
    state.offers = action.payload;
    state.status = OffersStatus.ready;
  });
}

function createOfferReducer(builder: ActionReducerMapBuilder<OffersState>) {
    builder.addCase(createOffer.fulfilled, (state, action) => {
      state.newOfferId = action.payload;
      state.status = OffersStatus.ready;
    });
  }
  
function getWorkflowReducer(builder: ActionReducerMapBuilder<OffersState>) {
  builder.addCase(getWorkflow.fulfilled, (state, action) => {
    state.workflow = action.payload;
    state.status = OffersStatus.ready;
  });
}

function getWorkspaceReducer(builder: ActionReducerMapBuilder<OffersState>) {
  builder.addCase(getWorkspace.fulfilled, (state, action) => {
    state.workspace = action.payload;
    state.status = OffersStatus.ready;
  });
}

export default offersSlice.reducer;
export const offersState = (state: RootState) => state.offers;
