import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Page from "../../components/page";
import { authCallbackState, AuthCallbackStatus } from "./auth-callback-slice";
import { assertUser } from "./thunks";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { withAuthenticationRequired } from "react-oidc-context";
import RedirectToLogin from "../../components/redirect-to-login";

const AuthCallback = () => {
  const state = useAppSelector(authCallbackState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/settings/my-details", { relative: "route" });
    return;
    if (state.status != AuthCallbackStatus.ready) {
      dispatch(assertUser());
    } else {
      if (state.isNewUser!) {
        navigate("/settings/my-details", { relative: "route" });
      }
    }
  }, [state.status, dispatch]);
  return (
    <Page title="LandConnex" showBackNavigation={false} showSideBar={false}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <CircularProgress />
        <Typography variant="h6" style={{ marginTop: "16px" }}>
          Working on it :-) Please wait...
        </Typography>
      </Box>
    </Page>
  );
};

export default withAuthenticationRequired(AuthCallback, {
  OnRedirecting: () => <RedirectToLogin />,
});
