import { Box, Grid, Typography, Card, CardContent, Paper } from "@mui/material";
import Button from "../../components/button";
import { useAppDispatch } from "../../app/hooks";
import { createCustomer } from "./create-subscription-slice";

const AccountFeatureSummary = () => {
  const dispatch = useAppDispatch();

  const handleStartSubscription = () => {
    dispatch(createCustomer());
  };

  return (
    <Box padding={4}>
      <Grid container spacing={4}>
        {/* Main Message */}
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            You do not yet have an subscription. Would you like to create one?
          </Typography>
        </Grid>

        {/* Call to Action Section */}
        <Grid item xs={12} id="call-to-action">
          <Card>
            <CardContent>
              <Typography variant="body1" paragraph>
                An subscription is required to create workspaces if your company is a
                real estate agency, or participate in a workspace if your
                company is a conveyancer or solicitors' firm. It requires a
                credit card and takes less than 3 minutes.
              </Typography>

              <Grid container spacing={4}>
                {/* Agent Features Panel */}
                <Grid item xs={12} md={6}>
                  <Paper elevation={3} style={{ padding: "16px" }}>
                    <Typography variant="h6" gutterBottom>
                      Agent Features
                    </Typography>
                    <ul>
                      <li>Create workspaces</li>
                      <li>Title search</li>
                      <li>...</li>
                    </ul>
                  </Paper>
                </Grid>

                {/* Solicitors Features Panel */}
                <Grid item xs={12} md={6}>
                  <Paper elevation={3} style={{ padding: "16px" }}>
                    <Typography variant="h6" gutterBottom>
                      Solicitors Features
                    </Typography>
                    <ul>
                      <li>Create workspaces</li>
                      <li>Title search</li>
                      <li>...</li>
                    </ul>
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* Create Account Button */}
          <Grid item xs={12} md={4}>
            <Button
              label={"Create Account"}
              onClick={handleStartSubscription}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountFeatureSummary;
