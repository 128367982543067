import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Page from "../../components/page";
import { Elements } from "@stripe/react-stripe-js";
import {
  createSubscriptionState,
  CreateSubscriptionStatus,
} from "./create-subscription-slice";
import { Stripe } from "@stripe/stripe-js";
import { Box, CircularProgress, Typography } from "@mui/material";
import SubscriptionDetails from "./subscription-details";
import CustomerInformation from "./customer-information";
import AccountFeatureSummary from "./account";
import { withAuthenticationRequired } from "react-oidc-context";
import RedirectToLogin from "../../components/redirect-to-login";
import { getTeam } from "./thunks";

export interface CreateSubscriptionParams {
  stripePromise: Promise<Stripe | null>;
}
const CreateSubscription: FC<CreateSubscriptionParams> = ({
  stripePromise,
}) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(createSubscriptionState);

  useEffect(() => {
    if (state.status == CreateSubscriptionStatus.initial) {
      dispatch(getTeam());
    }
  }, [dispatch, state.status]);

  console.log(state);

  switch (state.status) {
    case CreateSubscriptionStatus.showingSubscriptionBenefits:
      return (
        <Page title="Create Subscription">
          <AccountFeatureSummary />
        </Page>
      );

    case CreateSubscriptionStatus.creatingCustomer:
      return (
        <Page title="Create Subscription">
          <CustomerInformation />
        </Page>
      );

    case CreateSubscriptionStatus.collectingPayment:
      return (
        <Page title="Create Subscription">
          <Elements
            stripe={stripePromise}
            options={{ clientSecret: state.clientSecretID }}
          >
            <SubscriptionDetails />
          </Elements>
        </Page>
      );
    case CreateSubscriptionStatus.subscriptionCreated:
      return (
        <Page title="Create Subscription">
          <Typography> Subscription summary</Typography>
        </Page>
      );
    default:
      return (
        <Page title="Create Subscription">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
          >
            <CircularProgress />
            <Typography variant="h6" style={{ marginTop: "16px" }}>
              Working on it :-) Please wait...
            </Typography>
          </Box>
        </Page>
      );
  }
};

export default withAuthenticationRequired(CreateSubscription, {
  OnRedirecting: () => <RedirectToLogin />,
});
