import { configureStore } from "@reduxjs/toolkit";
import contractsReducer from "../features/contracts/contracts-slice";
import contractReducer from "../features/contract/contract-slice";
import offerStepperReducer from "../features/offer-stepper/offer-stepper-slice";
import offerDraftReducer from "../features/offer-draft/offer-drafts-slice";
import contractStepperReducer from "../features/contract-stepper/contract-stepper-slice";
import formSixReducer from "../features/agent-appointment/agent-appointment-slice";
import createWorkspaceReducer from "../features/create-workspace/create-workspace-slice";
import workspaceReducer from "../features/workspace/workspace-slice";
import workspacesReducer from "../features/workspaces/workspaces-slice";
import acceptInvitationReducer from "../features/accept-invitation/accept-invitation-slice";
import viewDocumentReducer from "../features/view-document/view-document-slice";
import settingsReducer from "../features/settings/settings-slice";
import manageUsersReducer from "../features/manage-users/manage-users-slice";
import offersReducer from "../features/offers/offers-slice";
import authCallbackReducer from "../features/auth-callback/auth-callback-slice";
import createSubscriptionReducer from "../features/create-subscription/create-subscription-slice";

export const store = configureStore({
  reducer: {
    contracts: contractsReducer,
    contract: contractReducer,
    offerStepper: offerStepperReducer,
    offerDraft: offerDraftReducer,
    contractStepper: contractStepperReducer,
    agentAppointment: formSixReducer,
    createWorkspace: createWorkspaceReducer,
    workspace: workspaceReducer,
    workspaces: workspacesReducer,
    acceptInvitation: acceptInvitationReducer,
    viewDocument: viewDocumentReducer,
    settings: settingsReducer,
    manageUsers: manageUsersReducer,
    offers: offersReducer,
    authCallback: authCallbackReducer,
    createSubscription: createSubscriptionReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["offerDraft/getPDF/fulfilled"],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
