import { FC, useCallback, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  debounce,
  Box,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  Participant,
  ParticipantParticipantTypeEnum,
} from "../../../generated";
import { OnParticipantChanged } from "../../../components/events";
import useDeepCompareEffect from "use-deep-compare-effect";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ConfirmationDialog from "../../../components/confirmation-dialog";
import ContactDetails from "../../../components/contact-details";
import ParticipantNameForm from "../../../components/participant-name-form";
import AddressForm from "../../../components/address-form";
import { participantSchema } from "./schemas";

export interface ClientDetailProps {
  client: Participant;
  index: number;
  onChanged: OnParticipantChanged;
  onDelete: OnParticipantChanged;
}

const ClientDetail: FC<ClientDetailProps> = ({
  client,
  index,
  onChanged,
  onDelete,
}) => {
  // Local state to manage the client data
  const [localClient, setLocalClient] = useState(client);

  // State for confirmation dialog
  const [confirmationDialogState, setConfirmationDialogState] = useState<
    number[]
  >([]);

  // Accessing MUI theme
  const theme = useTheme();

  // Form handling with react-hook-form
  const schema = participantSchema;
  const { handleSubmit, control, formState, setValue, reset, trigger } =
    useForm<Participant>({
      defaultValues: localClient,
      resolver: yupResolver(schema),
    });

  // Reset form when client prop changes
  useEffect(() => {
    setLocalClient(client); // Update local client when prop changes
    reset(client);
    trigger();
  }, [client]);

  // Handle form submission
  const onSubmit: SubmitHandler<Participant> = (data) => {
    onChanged(data); // Pass the updated client data to the parent component
  };

  // Watch changes in form data
  const watchedData = useWatch({
    control: control,
    defaultValue: localClient,
  });

  // Debounce form save to optimize performance
  const debouncedSave = useCallback(
    debounce(() => {
      console.log("Saving");
      handleSubmit(onSubmit)();
    }, 1000),
    []
  );

  // Deep compare effect to trigger save on form changes
  useDeepCompareEffect(() => {
    console.log("Triggered");
    if (formState.isDirty) {
      debouncedSave();
    }
  }, [watchedData]);

  return (
    <Box
      key={localClient.id}
      sx={{
        maxWidth: "1024px",
        margin: "15px auto",
        padding: { sm: "30px", xs: "30px 10px" },
        background: "#faf7f7",
        borderRadius: "30px",
        boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
      }}
    >
      <Grid item xs={12} sm={12}>
        <ConfirmationDialog
          open={confirmationDialogState.includes(localClient.id!)}
          message={`Remove ${
            watchedData.organisationName ||
            (watchedData.firstName && watchedData.lastName
              ? `${watchedData.firstName} ${watchedData.lastName}`
              : `Client ${index + 1}`)
          } from the contract?`}
          title="Remove client?"
          onClose={(result) => {
            if (result) {
              onDelete(localClient);
            }
            setConfirmationDialogState([]);
          }}
        />
      </Grid>

      <Grid item style={{ borderColor: pink[400], borderTop: 3 }}>
        <Grid container spacing={2}>
          {/* Client header section */}
          <Grid item xs={12} sm={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">
                {`${
                  watchedData.organisationName ||
                  (watchedData.firstName && watchedData.lastName
                    ? `${watchedData.firstName} ${watchedData.lastName}`
                    : `Client ${index + 1}`)
                }`}
              </Typography>
              <IconButton
                color="primary"
                onClick={() => setConfirmationDialogState([localClient.id!])}
              >
                <DeleteForeverOutlinedIcon fontSize="large" color="error" />
              </IconButton>
            </Box>
            <Divider />
          </Grid>

          {/* Select client type */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth variant="filled">
              <InputLabel size="small" id="clientTypeLabel">
                Client Type
              </InputLabel>
              <Select
                size="small"
                labelId="clientTypeLabel"
                name="participantType"
                label="Client Type"
                value={localClient.participantType}
                onChange={(event) => {
                  const updatedClient = {
                    ...localClient,
                    participantType: event.target
                      .value as ParticipantParticipantTypeEnum,
                  };
                  setLocalClient(updatedClient); // Update local client state
                  onChanged(updatedClient); // Call onChanged with updated client
                }}
              >
                {Object.values(ParticipantParticipantTypeEnum).map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Participant name form */}
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Name
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ParticipantNameForm
              control={control}
              captureMiddleName={true}
              captureGst={true}
              participantType={localClient.participantType!}
            />
          </Grid>

          {/* Contact details */}
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Contact Details
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ContactDetails control={control} />
          </Grid>

          {/* Address form */}
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Address
            </Typography>
          </Grid>
          <AddressForm
            control={control}
            address={localClient}
            setValue={setValue}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClientDetail;
