import Box from "@mui/material/Box";
import { useAppDispatch } from "../../app/hooks";
import Page from "../../components/page";
import { FormProvider, useForm } from "react-hook-form";
import FormInputText from "../../components/form-input-text";
import Button from "@mui/material/Button";
import { startSubscription } from "./thunks";

interface CustomerInformation {
  accountName: string;
}
const CustomerInformation = () => {
  const dispatch = useAppDispatch();
  const formMethods = useForm<CustomerInformation>();
  const { handleSubmit, control } = formMethods;

  const handleStartSubscription = (data: CustomerInformation) => {
    dispatch(startSubscription(data.accountName));
  };
  return (
    <Page>
      <Box>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleStartSubscription)}>
            <FormInputText
              control={control}
              name="accountName"
              label="Account Name"
              required={true}
            />
            <Button type="submit">Next</Button>
          </form>
        </FormProvider>
      </Box>
    </Page>
  );
};

export default CustomerInformation;
