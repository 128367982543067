import { FC, useEffect } from "react";
import Page from "../../components/page";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import MembersList from "./components/MembersList";
import { Participant } from "../../generated";
import { manageUsersState, ManageUsersStatus } from "./manage-users-slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  cancelInvitation,
  getTeam,
  getTeamInvitations,
  getTeamMembers,
  inviteUserToTeam,
} from "./thunks";
import PendingInvitations from "./components/PendingInvitations";
import { withAuthenticationRequired } from "react-oidc-context";
import RedirectToLogin from "../../components/redirect-to-login";

const ManageUsers: FC = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(manageUsersState);

  useEffect(() => {
    if (state.status === ManageUsersStatus.initial) {
      dispatch(getTeam());
      dispatch(getTeamMembers());
      dispatch(getTeamInvitations());
    }
  }, [state, dispatch]);

  const theme = useTheme();

  const handleAddInvite = (email: string) => {
    dispatch(inviteUserToTeam(email));
  };

  const handleDeleteInvite = (invitationId: number) => {
    dispatch(cancelInvitation(invitationId));
  };

  const handleDeleteMember = (email: string) => {
    console.log(email);

    // setMembers((prev) => prev.filter((member) => member.email !== email));
  };

  const handleUpdateMember = (updatedMember: Partial<Participant>) => {
    console.log(updatedMember);

    // setMembers((prev) =>
    //   prev.map((member) =>
    //     member.email === updatedMember.email ? updatedMember : member
    //   )
    // );
  };

  return (
    <Page title="Manage Users">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "40px 10px",
        }}
      >
        <Typography
          className="roboto-black"
          variant="h4"
          sx={{ color: "#8F1219" }}
        >
          {`Manage ${state.team.name} Member`}
        </Typography>
      </Box>
      <Grid
        container
        spacing={0}
        sx={{
          display: "flex",
          justifyContent: "center",
          maxWidth: "700px",
          margin: "0 auto",
          [theme.breakpoints.up("lg")]: {
            maxWidth: "100%",
          },
          [theme.breakpoints.down("lg")]: {
            flexDirection: "column-reverse",
            alignItems: "flex-start",
          },
        }}
      >
        {/* Members List */}
        <Grid item xs={12} lg={8} sx={{ padding: { lg: "8px", xs: "4px 0" } }}>
          <MembersList
            members={state.members}
            handleDeleteMember={handleDeleteMember}
            handleUpdateMember={handleUpdateMember}
          />
        </Grid>

        {/* Pending Invitations */}
        <Grid
          item
          xs={12}
          lg={4}
          sx={{ padding: { lg: "8px", xs: "4px 0" }, width: "100%" }}
        >
          <PendingInvitations
            teamInvitations={state.teamInvitations}
            handleAddInvite={handleAddInvite}
            handleDeleteInvite={handleDeleteInvite}
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export default withAuthenticationRequired(ManageUsers, {
  OnRedirecting: () => <RedirectToLogin />,
});
