import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Page from "../../components/page";
import WorkspaceContents from "../workspaces/components/workspace-contents";
import {
  WorkspacesStatus,
  workspacesState,
} from "../workspaces/workspaces-slice";
import { getWorkflowState, getWorkspaces } from "../workspaces/thunks";
import { useAuth, withAuthenticationRequired } from "react-oidc-context";
import { Box, Typography } from "@mui/material";
import styles from "./landing-page.module.css";
import { useNavigate } from "react-router-dom";
import LCButton from "../../components/button";
import RedirectToLogin from "../../components/redirect-to-login";

const LandingPage: React.FC = () => {
  const state = useAppSelector(workspacesState);
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated && state.status === WorkspacesStatus.initial) {
      dispatch(getWorkspaces());
    }
  }, [state.status, auth, dispatch]);

  useEffect(() => {
    state.workspaces.forEach((w) => {
      dispatch(getWorkflowState({ workspaceId: w.id }));
    });
  }, [state.workspaces, dispatch]);

  return (
    <Page>
      <Box className={`${styles["banner-wrapper"]}`}>
        <Box className={`${styles["banner"]}`}>
          <Box className={`${styles["text"]}`}>
            <Box sx={{ marginBottom: "30px" }}>
              <Typography className="roboto-black" variant="h3">
                Unlock the Door to Your Future: Begin Your Journey with Us
              </Typography>
              <p className="roboto-light">
                At LandConnex, we understand that finding the perfect home is
                more than just a transaction; it’s a journey filled with dreams,
                hopes, and aspirations.
              </p>
              <p className="roboto-light">
                Our mission is to simplify this journey and make your
                home-buying experience as smooth and enjoyable as possible.
              </p>
            </Box>
            <Box sx={{ width: "300px", margin: "0 auto" }}>
              <LCButton label="Learn More" color="#000" size="large" />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          background: "#fff",
          padding: {
            sm: "40px 20px 40px 20px",
            xs: "5px",
          },
          borderRadius: "0 0 20px 20px",
        }}
      >
        {state.workspaces.length >= 1 && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <Typography
                className="roboto-black"
                variant="h4"
                sx={{ color: "#8F1219" }}
              >
                Our Workspace
              </Typography>
              <Box
                sx={{
                  width: "150px",
                }}
              >
                <LCButton
                  label="See More"
                  color="#8F1219"
                  onClick={() => navigate("/workspaces")}
                />
              </Box>
            </Box>
            <WorkspaceContents
              workspaces={state.workspaces}
              isCardView={true}
              workflowStates={state.workflowStates}
              maxItems={8}
            />
          </>
        )}

        {/* <Box
          className={`${styles["whys"]}`}
          sx={{
            display: "flex",
            position: "relative",
            overflow: "hidden",
          }}
          ref={parentRef}
        >
          <MailListing formRef={formRef} parentRef={parentRef} />
          <Whys />
        </Box> */}
      </Box>
    </Page>
  );
};

export default withAuthenticationRequired(LandingPage, {
  OnRedirecting: () => <RedirectToLogin />,
});
// export default LandingPage;
