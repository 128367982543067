/* tslint:disable */
/* eslint-disable */
/**
 * Conveyancing Documents
 * Search for and retrieve brokered conveyancing documents from federal, state and  local government jurisdictions. 
 *
 * The version of the OpenAPI document: 1.0.14
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface GetTitleResponse
 */
export interface GetTitleResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetTitleResponse
     */
    'deferred': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetTitleResponse
     */
    'url': string;
    /**
     * 
     * @type {Title}
     * @memberof GetTitleResponse
     */
    'title'?: Title;
}
/**
 * 
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
    /**
     * 
     * @type {Array<TitleSearchResult>}
     * @memberof SearchResult
     */
    'items'?: Array<TitleSearchResult>;
}
/**
 * 
 * @export
 * @interface Title
 */
export interface Title {
    /**
     * 
     * @type {string}
     * @memberof Title
     */
    'reference'?: string;
}
/**
 * 
 * @export
 * @interface TitleSearchResult
 */
export interface TitleSearchResult {
    /**
     * The unique identifier of the title. This property is used  when getting the title. 
     * @type {string}
     * @memberof TitleSearchResult
     */
    'reference': string;
    /**
     * 
     * @type {string}
     * @memberof TitleSearchResult
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof TitleSearchResult
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TitleSearchResult
     */
    'address': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TitleSearchResult
     */
    'aliases'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TitleSearchResult
     */
    'lotOnPlan': string;
    /**
     * The name of the owner. Either an organisation name, or the first and last names  of the owner 
     * @type {string}
     * @memberof TitleSearchResult
     */
    'ownerName': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrives a title from a titles registry. The request is fulfilled and returns  the title and a url for the PDF. A workspace ID must be provided. The caller will be  charged for the title. 
         * @summary Get a title
         * @param {number} workspaceId The workspace identifer
         * @param {string} titleReference The title reference identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTitle: async (workspaceId: number, titleReference: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getTitle', 'workspaceId', workspaceId)
            // verify required parameter 'titleReference' is not null or undefined
            assertParamExists('getTitle', 'titleReference', titleReference)
            const localVarPath = `/titles/{titleReference}`
                .replace(`{${"titleReference"}}`, encodeURIComponent(String(titleReference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication landconnex_oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "landconnex_oauth2", ["read"], configuration)

            if (workspaceId !== undefined) {
                localVarQueryParameter['workspace-id'] = workspaceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search a title registry for a title using the address. The caller will be  charged for the search. 
         * @summary Search for a title
         * @param {string} streetAddress The address search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByAddress: async (streetAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'streetAddress' is not null or undefined
            assertParamExists('searchByAddress', 'streetAddress', streetAddress)
            const localVarPath = `/titles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication landconnex_oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "landconnex_oauth2", ["read"], configuration)

            if (streetAddress !== undefined) {
                localVarQueryParameter['street-address'] = streetAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication landconnex_oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "landconnex_oauth2", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrives a title from a titles registry. The request is fulfilled and returns  the title and a url for the PDF. A workspace ID must be provided. The caller will be  charged for the title. 
         * @summary Get a title
         * @param {number} workspaceId The workspace identifer
         * @param {string} titleReference The title reference identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTitle(workspaceId: number, titleReference: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTitleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTitle(workspaceId, titleReference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search a title registry for a title using the address. The caller will be  charged for the search. 
         * @summary Search for a title
         * @param {string} streetAddress The address search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchByAddress(streetAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchByAddress(streetAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get the status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statusGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statusGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Retrives a title from a titles registry. The request is fulfilled and returns  the title and a url for the PDF. A workspace ID must be provided. The caller will be  charged for the title. 
         * @summary Get a title
         * @param {number} workspaceId The workspace identifer
         * @param {string} titleReference The title reference identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTitle(workspaceId: number, titleReference: string, options?: any): AxiosPromise<GetTitleResponse> {
            return localVarFp.getTitle(workspaceId, titleReference, options).then((request) => request(axios, basePath));
        },
        /**
         * Search a title registry for a title using the address. The caller will be  charged for the search. 
         * @summary Search for a title
         * @param {string} streetAddress The address search term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByAddress(streetAddress: string, options?: any): AxiosPromise<SearchResult> {
            return localVarFp.searchByAddress(streetAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusGet(options?: any): AxiosPromise<string> {
            return localVarFp.statusGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Retrives a title from a titles registry. The request is fulfilled and returns  the title and a url for the PDF. A workspace ID must be provided. The caller will be  charged for the title. 
     * @summary Get a title
     * @param {number} workspaceId The workspace identifer
     * @param {string} titleReference The title reference identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTitle(workspaceId: number, titleReference: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTitle(workspaceId, titleReference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search a title registry for a title using the address. The caller will be  charged for the search. 
     * @summary Search for a title
     * @param {string} streetAddress The address search term
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public searchByAddress(streetAddress: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).searchByAddress(streetAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public statusGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).statusGet(options).then((request) => request(this.axios, this.basePath));
    }
}



