/* tslint:disable */
/* eslint-disable */
/**
 * LandConnex API
 * LandConnex API
 *
 * The version of the OpenAPI document: 3.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * A workflow activity
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'stage'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Activity
     */
    'isComplete': boolean;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'summary'?: string;
}
/**
 * 
 * @export
 * @interface ActivityLog
 */
export interface ActivityLog {
    /**
     * 
     * @type {number}
     * @memberof ActivityLog
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ActivityLog
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLog
     */
    'summary': string;
}
/**
 * 
 * @export
 * @interface ActivityLogResponse
 */
export interface ActivityLogResponse {
    /**
     * 
     * @type {Array<ActivityLog>}
     * @memberof ActivityLogResponse
     */
    'items'?: Array<ActivityLog>;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'streetAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'streetAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'locality'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'stateOrTerritory': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'postCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface AgentAppointment
 */
export interface AgentAppointment {
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'isRealEstateAgent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'isLettingAgent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'isPropertyAuctioneer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'tradingName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'licenceeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'licenceeNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'licenceeExpiryDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'appointmentIsSale'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'appointmentIsPurchase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'appointmentIsLetting'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'appointmentIsLeasing'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'appointmentIsAuction'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'appointmentIsOther'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'appointmentIsOtherDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'auctionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'appointmentTermStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'appointmentTermEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'appointmentTermType'?: AgentAppointmentAppointmentTermTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'priceReserve'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'priceList'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'priceLetting'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'priceMarketingRange'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'instructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'appointmentType'?: AgentAppointmentAppointmentTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AgentAppointment
     */
    'isSoleOrExclusiveContinuation'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'commissionInstructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'foo'?: AgentAppointmentFooEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'commissionPaymentTerm'?: AgentAppointmentCommissionPaymentTermEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'commissionPaymentTermOtherAppointments'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'commissionPaymentTermInstructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'marketingInstructions'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'marketingAuthorisedAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'repairsAndMaintenanceAuthorisedAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentAppointment
     */
    'repairsAndMaintenancePropertyAuthorisedAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointment
     */
    'marketingCostsDueDate'?: string;
}

export const AgentAppointmentAppointmentTermTypeEnum = {
    Single: 'single',
    Continuing: 'continuing'
} as const;

export type AgentAppointmentAppointmentTermTypeEnum = typeof AgentAppointmentAppointmentTermTypeEnum[keyof typeof AgentAppointmentAppointmentTermTypeEnum];
export const AgentAppointmentAppointmentTypeEnum = {
    Open: 'open',
    Sole: 'sole',
    Exclusive: 'exclusive'
} as const;

export type AgentAppointmentAppointmentTypeEnum = typeof AgentAppointmentAppointmentTypeEnum[keyof typeof AgentAppointmentAppointmentTypeEnum];
export const AgentAppointmentFooEnum = {
    Settlement: 'settlement',
    Other: 'other'
} as const;

export type AgentAppointmentFooEnum = typeof AgentAppointmentFooEnum[keyof typeof AgentAppointmentFooEnum];
export const AgentAppointmentCommissionPaymentTermEnum = {
    Settlement: 'settlement',
    Other: 'other'
} as const;

export type AgentAppointmentCommissionPaymentTermEnum = typeof AgentAppointmentCommissionPaymentTermEnum[keyof typeof AgentAppointmentCommissionPaymentTermEnum];

/**
 * 
 * @export
 * @interface AgentAppointmentStatus
 */
export interface AgentAppointmentStatus {
    /**
     * 
     * @type {string}
     * @memberof AgentAppointmentStatus
     */
    'status': string;
    /**
     * 
     * @type {Array<AgentAppointmentStatusRecipientsInner>}
     * @memberof AgentAppointmentStatus
     */
    'recipients'?: Array<AgentAppointmentStatusRecipientsInner>;
}
/**
 * 
 * @export
 * @interface AgentAppointmentStatusRecipientsInner
 */
export interface AgentAppointmentStatusRecipientsInner {
    /**
     * 
     * @type {string}
     * @memberof AgentAppointmentStatusRecipientsInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointmentStatusRecipientsInner
     */
    'statusDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentAppointmentStatusRecipientsInner
     */
    'status'?: AgentAppointmentStatusRecipientsInnerStatusEnum;
}

export const AgentAppointmentStatusRecipientsInnerStatusEnum = {
    Created: 'created',
    Pending: 'pending',
    Sent: 'sent',
    Failed: 'failed',
    Completed: 'completed',
    Declined: 'declined'
} as const;

export type AgentAppointmentStatusRecipientsInnerStatusEnum = typeof AgentAppointmentStatusRecipientsInnerStatusEnum[keyof typeof AgentAppointmentStatusRecipientsInnerStatusEnum];

/**
 * 
 * @export
 * @interface Annexure
 */
export interface Annexure {
    /**
     * 
     * @type {number}
     * @memberof Annexure
     */
    'id': number;
    /**
     * 
     * @type {AnnexureDocument}
     * @memberof Annexure
     */
    'document': AnnexureDocument;
    /**
     * 
     * @type {number}
     * @memberof Annexure
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface AnnexureDocument
 */
export interface AnnexureDocument {
    /**
     * 
     * @type {string}
     * @memberof AnnexureDocument
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnexureDocument
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnexureDocument
     */
    'documentType'?: AnnexureDocumentDocumentTypeEnum;
}

export const AnnexureDocumentDocumentTypeEnum = {
    AgentAppointment: 'AgentAppointment',
    ContractOfSale: 'ContractOfSale',
    Annexure: 'Annexure'
} as const;

export type AnnexureDocumentDocumentTypeEnum = typeof AnnexureDocumentDocumentTypeEnum[keyof typeof AnnexureDocumentDocumentTypeEnum];

/**
 * 
 * @export
 * @interface AssertUserResponse
 */
export interface AssertUserResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AssertUserResponse
     */
    'newUser': boolean;
}
/**
 * 
 * @export
 * @interface BenefitToAgent
 */
export interface BenefitToAgent {
    /**
     * 
     * @type {number}
     * @memberof BenefitToAgent
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BenefitToAgent
     */
    'service'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenefitToAgent
     */
    'source'?: string;
    /**
     * 
     * @type {number}
     * @memberof BenefitToAgent
     */
    'estimatedAmount'?: number;
}
/**
 * 
 * @export
 * @interface BenefitToAgents
 */
export interface BenefitToAgents {
    /**
     * 
     * @type {Array<BenefitToAgent>}
     * @memberof BenefitToAgents
     */
    'items'?: Array<BenefitToAgent>;
}
/**
 * 
 * @export
 * @interface CompleteSubscriptionRequest
 */
export interface CompleteSubscriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof CompleteSubscriptionRequest
     */
    'setupIntentID': string;
}
/**
 * 
 * @export
 * @interface ContactDetails
 */
export interface ContactDetails {
    /**
     * 
     * @type {string}
     * @memberof ContactDetails
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDetails
     */
    'mobilePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDetails
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface Contract
 */
export interface Contract {
    /**
     * 
     * @type {number}
     * @memberof Contract
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'reference'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'externalReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    'hasEncumbrances': boolean;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'encumbrances'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    'hasPool': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    'hasPoolCertificate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    'hasSafetySwitches': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    'hasSmokeAlarms': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    'hasNeighbourhoodDisputes': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    'hasTenant': boolean;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'depositHolderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'depositHolderAccountName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'depositHolderBank'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'depositHolderBsb'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'depositHolderAccountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'tenancyTenantName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'tenancyTermAndOptions'?: string;
    /**
     * 
     * @type {number}
     * @memberof Contract
     */
    'tenancyBond'?: number;
    /**
     * 
     * @type {number}
     * @memberof Contract
     */
    'tenancyRent'?: number;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'tenancyTermStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'tenancyTermEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'excludedFixtures'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'includedChattels'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'specialConditions'?: string;
    /**
     * True if the seller is represented by a solicitor at the time of contract signing, false otherwise.
     * @type {boolean}
     * @memberof Contract
     */
    'hasSellerSolicitor': boolean;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'depositHolder'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'trustAccount'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'bank'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'bsb'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'account'?: string;
    /**
     * the total purchase amount in cents
     * @type {number}
     * @memberof Contract
     */
    'purchasePrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof Contract
     */
    'depositInitial'?: number;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'depositInitialDue'?: string;
    /**
     * 
     * @type {number}
     * @memberof Contract
     */
    'depositBalance'?: number;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'depositBalanceDue'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'defaultInterestRate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'financier'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'financeAmount'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'financeDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    'buyerRegisteredForGst'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'buildingAndPestInspection'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    'settlementDate'?: string;
}
/**
 * 
 * @export
 * @interface ContractAnnexuresResponse
 */
export interface ContractAnnexuresResponse {
    /**
     * 
     * @type {Array<Annexure>}
     * @memberof ContractAnnexuresResponse
     */
    'items': Array<Annexure>;
}
/**
 * 
 * @export
 * @interface ConveyanceWorkflow
 */
export interface ConveyanceWorkflow {
    /**
     * 
     * @type {boolean}
     * @memberof ConveyanceWorkflow
     */
    'hasDeposit': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConveyanceWorkflow
     */
    'depositUploaded': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConveyanceWorkflow
     */
    'depositReceived': boolean;
}
/**
 * 
 * @export
 * @interface CreateSubscriptionResponse
 */
export interface CreateSubscriptionResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionResponse
     */
    'setupIntentID': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionResponse
     */
    'clientSecretID': string;
}
/**
 * 
 * @export
 * @interface Created
 */
export interface Created {
    /**
     * 
     * @type {string}
     * @memberof Created
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Created
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Created
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Created
     */
    'modifiedBy'?: string;
}
/**
 * 
 * @export
 * @interface DocumentSummary
 */
export interface DocumentSummary {
    /**
     * 
     * @type {string}
     * @memberof DocumentSummary
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentSummary
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentSummary
     */
    'documentType'?: DocumentSummaryDocumentTypeEnum;
}

export const DocumentSummaryDocumentTypeEnum = {
    AgentAppointment: 'AgentAppointment',
    ContractOfSale: 'ContractOfSale',
    Annexure: 'Annexure'
} as const;

export type DocumentSummaryDocumentTypeEnum = typeof DocumentSummaryDocumentTypeEnum[keyof typeof DocumentSummaryDocumentTypeEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentType = {
    AgentAppointment: 'AgentAppointment',
    ContractOfSale: 'ContractOfSale',
    Annexure: 'Annexure'
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];


/**
 * 
 * @export
 * @interface Documents
 */
export interface Documents {
    /**
     * 
     * @type {Array<AnnexureDocument>}
     * @memberof Documents
     */
    'items'?: Array<AnnexureDocument>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ESigningStatus = {
    Created: 'created',
    Pending: 'pending',
    Sent: 'sent',
    Failed: 'failed',
    Completed: 'completed',
    Declined: 'declined'
} as const;

export type ESigningStatus = typeof ESigningStatus[keyof typeof ESigningStatus];


/**
 * 
 * @export
 * @interface EnvelopeChangedRequest
 */
export interface EnvelopeChangedRequest {
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'envelope_id': string;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'event': string;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'created'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'sent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'voided'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'viewed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'declined'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'completed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'failed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'recipient_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'certificate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'master'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'method'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'declined_reason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeChangedRequest
     */
    'diagnosis'?: string | null;
}
/**
 * 
 * @export
 * @interface EnvelopeUpdate
 */
export interface EnvelopeUpdate {
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'envelope_id': string;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'event': string;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'created'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'sent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'voided'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'viewed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'declined'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'completed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'failed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'recipient_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'certificate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'master'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'method'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'declined_reason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeUpdate
     */
    'diagnosis'?: string | null;
}
/**
 * 
 * @export
 * @interface Fee
 */
export interface Fee {
    /**
     * 
     * @type {number}
     * @memberof Fee
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Fee
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Fee
     */
    'whenPayable'?: string;
    /**
     * 
     * @type {number}
     * @memberof Fee
     */
    'estimatedAmount'?: number;
}
/**
 * 
 * @export
 * @interface Fees
 */
export interface Fees {
    /**
     * 
     * @type {Array<Fee>}
     * @memberof Fees
     */
    'items'?: Array<Fee>;
}
/**
 * 
 * @export
 * @interface Flat
 */
export interface Flat {
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredFlatType}
     * @memberof Flat
     */
    'type'?: PhysicalAustralianAddressStructuredFlatType;
    /**
     * 
     * @type {string}
     * @memberof Flat
     */
    'prefix'?: string;
    /**
     * 
     * @type {number}
     * @memberof Flat
     */
    'number'?: number;
    /**
     * 
     * @type {string}
     * @memberof Flat
     */
    'suffix'?: string;
}
/**
 * 
 * @export
 * @interface FlattenedAddress
 */
export interface FlattenedAddress {
    /**
     * The full address flattened to a single line. The primary use is for search results and for autocomplete.
     * @type {string}
     * @memberof FlattenedAddress
     */
    'sla': string;
    /**
     * The full address flattened to a single line. The primary use is for search results and for autocomplete.
     * @type {string}
     * @memberof FlattenedAddress
     */
    'ssla'?: string;
    /**
     * 
     * @type {FlattenedAddressHighlight}
     * @memberof FlattenedAddress
     */
    'highlight'?: FlattenedAddressHighlight;
    /**
     * strength of the match relative to the other matches
     * @type {number}
     * @memberof FlattenedAddress
     */
    'score': number;
    /**
     * The Persistent Identifier is unique to the real world feature this record represents.
     * @type {string}
     * @memberof FlattenedAddress
     */
    'pid': string;
}
/**
 * 
 * @export
 * @interface FlattenedAddressHighlight
 */
export interface FlattenedAddressHighlight {
    /**
     * The full address flattened to a single line with matching search tokens highlighted
     * @type {string}
     * @memberof FlattenedAddressHighlight
     */
    'sla': string;
    /**
     * The full address flattened to a single line with matching search tokens highlighted
     * @type {string}
     * @memberof FlattenedAddressHighlight
     */
    'ssla'?: string;
}
/**
 * 
 * @export
 * @interface FlattenedAddresses
 */
export interface FlattenedAddresses {
    /**
     * 
     * @type {Array<FlattenedAddress>}
     * @memberof FlattenedAddresses
     */
    'items'?: Array<FlattenedAddress>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Foo = {
    Settlement: 'settlement',
    Other: 'other'
} as const;

export type Foo = typeof Foo[keyof typeof Foo];


/**
 * 
 * @export
 * @interface GetDocuments200Response
 */
export interface GetDocuments200Response {
    /**
     * 
     * @type {Array<AnnexureDocument>}
     * @memberof GetDocuments200Response
     */
    'items'?: Array<AnnexureDocument>;
}
/**
 * 
 * @export
 * @interface GetLicenceResponse
 */
export interface GetLicenceResponse {
    /**
     * 
     * @type {TenantContact}
     * @memberof GetLicenceResponse
     */
    'licence': TenantContact;
}
/**
 * 
 * @export
 * @interface GetMyDetailsResponse
 */
export interface GetMyDetailsResponse {
    /**
     * 
     * @type {Participant1}
     * @memberof GetMyDetailsResponse
     */
    'user': Participant1;
}
/**
 * 
 * @export
 * @interface GetTeamInvitationsResposne
 */
export interface GetTeamInvitationsResposne {
    /**
     * 
     * @type {Array<TeamInvitation>}
     * @memberof GetTeamInvitationsResposne
     */
    'items': Array<TeamInvitation>;
}
/**
 * 
 * @export
 * @interface GetUsersResponse
 */
export interface GetUsersResponse {
    /**
     * 
     * @type {Array<TeamParticipant1>}
     * @memberof GetUsersResponse
     */
    'users'?: Array<TeamParticipant1>;
}
/**
 * 
 * @export
 * @interface Highlight
 */
export interface Highlight {
    /**
     * The full address flattened to a single line with matching search tokens highlighted
     * @type {string}
     * @memberof Highlight
     */
    'sla': string;
    /**
     * The full address flattened to a single line with matching search tokens highlighted
     * @type {string}
     * @memberof Highlight
     */
    'ssla'?: string;
}
/**
 * 
 * @export
 * @interface Invitation
 */
export interface Invitation {
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'emailAddress'?: string;
}
/**
 * 
 * @export
 * @interface Locality
 */
export interface Locality {
    /**
     * 
     * @type {string}
     * @memberof Locality
     */
    'name'?: string;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredStreetType}
     * @memberof Locality
     */
    'class'?: PhysicalAustralianAddressStructuredStreetType;
}
/**
 * 
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'modifiedBy'?: string;
}
/**
 * 
 * @export
 * @interface NewEntity
 */
export interface NewEntity {
    /**
     * 
     * @type {number}
     * @memberof NewEntity
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface OfferDetail
 */
export interface OfferDetail {
    /**
     * 
     * @type {Contract}
     * @memberof OfferDetail
     */
    'offer': Contract;
    /**
     * 
     * @type {Array<Participant1>}
     * @memberof OfferDetail
     */
    'participants': Array<Participant1>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OfferSigningState = {
    SigningNotStarted: 'SigningNotStarted',
    SigningReady: 'SigningReady',
    SigningSentForSigning: 'SigningSentForSigning',
    SigningComplete: 'SigningComplete',
    SigningDeclined: 'SigningDeclined'
} as const;

export type OfferSigningState = typeof OfferSigningState[keyof typeof OfferSigningState];


/**
 * 
 * @export
 * @enum {string}
 */

export const OfferState = {
    OfferCreated: 'OfferCreated',
    OfferNegotiatingContract: 'OfferNegotiatingContract',
    OfferSigning: 'OfferSigning',
    OfferCancelled: 'OfferCancelled',
    OfferComplete: 'OfferComplete'
} as const;

export type OfferState = typeof OfferState[keyof typeof OfferState];


/**
 * 
 * @export
 * @interface OfferStatus
 */
export interface OfferStatus {
    /**
     * 
     * @type {number}
     * @memberof OfferStatus
     */
    'offerId': number;
    /**
     * 
     * @type {string}
     * @memberof OfferStatus
     */
    'buyerState': OfferStatusBuyerStateEnum;
    /**
     * 
     * @type {string}
     * @memberof OfferStatus
     */
    'sellerState': OfferStatusSellerStateEnum;
    /**
     * 
     * @type {string}
     * @memberof OfferStatus
     */
    'state': OfferStatusStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OfferStatus
     */
    'buyerSolicitorInvited': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfferStatus
     */
    'buyerInvited': boolean;
}

export const OfferStatusBuyerStateEnum = {
    SigningNotStarted: 'SigningNotStarted',
    SigningReady: 'SigningReady',
    SigningSentForSigning: 'SigningSentForSigning',
    SigningComplete: 'SigningComplete',
    SigningDeclined: 'SigningDeclined'
} as const;

export type OfferStatusBuyerStateEnum = typeof OfferStatusBuyerStateEnum[keyof typeof OfferStatusBuyerStateEnum];
export const OfferStatusSellerStateEnum = {
    SigningNotStarted: 'SigningNotStarted',
    SigningReady: 'SigningReady',
    SigningSentForSigning: 'SigningSentForSigning',
    SigningComplete: 'SigningComplete',
    SigningDeclined: 'SigningDeclined'
} as const;

export type OfferStatusSellerStateEnum = typeof OfferStatusSellerStateEnum[keyof typeof OfferStatusSellerStateEnum];
export const OfferStatusStateEnum = {
    OfferCreated: 'OfferCreated',
    OfferNegotiatingContract: 'OfferNegotiatingContract',
    OfferSigning: 'OfferSigning',
    OfferCancelled: 'OfferCancelled',
    OfferComplete: 'OfferComplete'
} as const;

export type OfferStatusStateEnum = typeof OfferStatusStateEnum[keyof typeof OfferStatusStateEnum];

/**
 * 
 * @export
 * @interface OfferSummary
 */
export interface OfferSummary {
    /**
     * 
     * @type {number}
     * @memberof OfferSummary
     */
    'offerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferSummary
     */
    'buyerName'?: string;
}
/**
 * 
 * @export
 * @interface Offers
 */
export interface Offers {
    /**
     * 
     * @type {Array<OfferDetail>}
     * @memberof Offers
     */
    'items'?: Array<OfferDetail>;
}
/**
 * 
 * @export
 * @interface Participant
 */
export interface Participant {
    /**
     * 
     * @type {number}
     * @memberof Participant
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'externalReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'participantType'?: ParticipantParticipantTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'role'?: ParticipantRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'middleNames'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'organisationName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'streetAddress1'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'streetAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'locality'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'stateOrTerritory'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'postCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'mobilePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'abn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'acn'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Participant
     */
    'registeredForGst'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'licenceeNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'licenceeExpiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    'tradingName'?: string;
}

export const ParticipantParticipantTypeEnum = {
    Individual: 'individual',
    Organisation: 'organisation',
    Trust: 'trust'
} as const;

export type ParticipantParticipantTypeEnum = typeof ParticipantParticipantTypeEnum[keyof typeof ParticipantParticipantTypeEnum];
export const ParticipantRoleEnum = {
    Seller: 'seller',
    SellerAgent: 'sellerAgent',
    SellerSolicitor: 'sellerSolicitor',
    Buyer: 'buyer',
    BuyerSolicitor: 'buyerSolicitor',
    Licencee: 'licencee'
} as const;

export type ParticipantRoleEnum = typeof ParticipantRoleEnum[keyof typeof ParticipantRoleEnum];

/**
 * 
 * @export
 * @interface Participant1
 */
export interface Participant1 {
    /**
     * 
     * @type {number}
     * @memberof Participant1
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'externalReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'participantType'?: Participant1ParticipantTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'role'?: Participant1RoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'middleNames'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'organisationName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'streetAddress1'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'streetAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'locality'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'stateOrTerritory'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'postCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'mobilePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'abn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'acn'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Participant1
     */
    'registeredForGst'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'licenceeNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'licenceeExpiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant1
     */
    'tradingName'?: string;
}

export const Participant1ParticipantTypeEnum = {
    Individual: 'individual',
    Organisation: 'organisation',
    Trust: 'trust'
} as const;

export type Participant1ParticipantTypeEnum = typeof Participant1ParticipantTypeEnum[keyof typeof Participant1ParticipantTypeEnum];
export const Participant1RoleEnum = {
    Seller: 'seller',
    SellerAgent: 'sellerAgent',
    SellerSolicitor: 'sellerSolicitor',
    Buyer: 'buyer',
    BuyerSolicitor: 'buyerSolicitor',
    Licencee: 'licencee'
} as const;

export type Participant1RoleEnum = typeof Participant1RoleEnum[keyof typeof Participant1RoleEnum];

/**
 * 
 * @export
 * @interface Participants
 */
export interface Participants {
    /**
     * 
     * @type {Array<Participant1>}
     * @memberof Participants
     */
    'items'?: Array<Participant1>;
}
/**
 * 
 * @export
 * @interface PerformTaskRequest
 */
export interface PerformTaskRequest {
    [key: string]: string;

    /**
     * 
     * @type {string}
     * @memberof PerformTaskRequest
     */
    'taskName': string;
}
/**
 * A physical Australian address in structured and unstructured formats. If an address has a \"flat\" component (e.g. UNIT 12, 32 SOME ST...), then it will also have  the `ssla` and `smla` fields, which are shortened versions of `sla` and `mla`. 
 * @export
 * @interface PhysicalAustralianAddress
 */
export interface PhysicalAustralianAddress {
    /**
     * The Persistent Identifier is unique to the real world feature this record represents. 
     * @type {string}
     * @memberof PhysicalAustralianAddress
     */
    'pid': string;
    /**
     * 
     * @type {PhysicalAustralianAddressStructured}
     * @memberof PhysicalAustralianAddress
     */
    'structured': PhysicalAustralianAddressStructured;
    /**
     * The full address flattened to a single line.
     * @type {string}
     * @memberof PhysicalAustralianAddress
     */
    'sla': string;
    /**
     * The shortened version of the Single Line Address.
     * @type {string}
     * @memberof PhysicalAustralianAddress
     */
    'ssla'?: string;
    /**
     * The full address flattened to at most four lines.
     * @type {Array<string>}
     * @memberof PhysicalAustralianAddress
     */
    'mla': Array<string>;
    /**
     * The shortened version of the Multi Line Address.
     * @type {Array<string>}
     * @memberof PhysicalAustralianAddress
     */
    'smla'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PhysicalAustralianAddressStructured
 */
export interface PhysicalAustralianAddressStructured {
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructured
     */
    'buildingName'?: string;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredLotNumber}
     * @memberof PhysicalAustralianAddressStructured
     */
    'lotNumber'?: PhysicalAustralianAddressStructuredLotNumber;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredFlat}
     * @memberof PhysicalAustralianAddressStructured
     */
    'flat'?: PhysicalAustralianAddressStructuredFlat;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredLotNumber}
     * @memberof PhysicalAustralianAddressStructured
     */
    'level'?: PhysicalAustralianAddressStructuredLotNumber;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredLotNumber}
     * @memberof PhysicalAustralianAddressStructured
     */
    'number'?: PhysicalAustralianAddressStructuredLotNumber;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredStreet}
     * @memberof PhysicalAustralianAddressStructured
     */
    'street'?: PhysicalAustralianAddressStructuredStreet;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredLocality}
     * @memberof PhysicalAustralianAddressStructured
     */
    'locality'?: PhysicalAustralianAddressStructuredLocality;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredState}
     * @memberof PhysicalAustralianAddressStructured
     */
    'state'?: PhysicalAustralianAddressStructuredState;
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructured
     */
    'postcode'?: string;
    /**
     * 
     * @type {number}
     * @memberof PhysicalAustralianAddressStructured
     */
    'confidence'?: number;
}
/**
 * 
 * @export
 * @interface PhysicalAustralianAddressStructuredFlat
 */
export interface PhysicalAustralianAddressStructuredFlat {
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredFlatType}
     * @memberof PhysicalAustralianAddressStructuredFlat
     */
    'type'?: PhysicalAustralianAddressStructuredFlatType;
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredFlat
     */
    'prefix'?: string;
    /**
     * 
     * @type {number}
     * @memberof PhysicalAustralianAddressStructuredFlat
     */
    'number'?: number;
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredFlat
     */
    'suffix'?: string;
}
/**
 * 
 * @export
 * @interface PhysicalAustralianAddressStructuredFlatType
 */
export interface PhysicalAustralianAddressStructuredFlatType {
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredFlatType
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredFlatType
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface PhysicalAustralianAddressStructuredLocality
 */
export interface PhysicalAustralianAddressStructuredLocality {
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredLocality
     */
    'name'?: string;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredStreetType}
     * @memberof PhysicalAustralianAddressStructuredLocality
     */
    'class'?: PhysicalAustralianAddressStructuredStreetType;
}
/**
 * 
 * @export
 * @interface PhysicalAustralianAddressStructuredLotNumber
 */
export interface PhysicalAustralianAddressStructuredLotNumber {
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredLotNumber
     */
    'prefix'?: string;
    /**
     * 
     * @type {number}
     * @memberof PhysicalAustralianAddressStructuredLotNumber
     */
    'number'?: number;
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredLotNumber
     */
    'suffix'?: string;
}
/**
 * 
 * @export
 * @interface PhysicalAustralianAddressStructuredState
 */
export interface PhysicalAustralianAddressStructuredState {
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredState
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredState
     */
    'abbreviation'?: string;
}
/**
 * 
 * @export
 * @interface PhysicalAustralianAddressStructuredStreet
 */
export interface PhysicalAustralianAddressStructuredStreet {
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredStreet
     */
    'name'?: string;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredStreetType}
     * @memberof PhysicalAustralianAddressStructuredStreet
     */
    'type'?: PhysicalAustralianAddressStructuredStreetType;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredStreetType}
     * @memberof PhysicalAustralianAddressStructuredStreet
     */
    'suffix'?: PhysicalAustralianAddressStructuredStreetType;
}
/**
 * 
 * @export
 * @interface PhysicalAustralianAddressStructuredStreetType
 */
export interface PhysicalAustralianAddressStructuredStreetType {
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredStreetType
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhysicalAustralianAddressStructuredStreetType
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface PrefixNumberSuffix
 */
export interface PrefixNumberSuffix {
    /**
     * 
     * @type {string}
     * @memberof PrefixNumberSuffix
     */
    'prefix'?: string;
    /**
     * 
     * @type {number}
     * @memberof PrefixNumberSuffix
     */
    'number'?: number;
    /**
     * 
     * @type {string}
     * @memberof PrefixNumberSuffix
     */
    'suffix'?: string;
}
/**
 * 
 * @export
 * @interface Property
 */
export interface Property {
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'streetAddress1'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'streetAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'locality'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'stateOrTerritory'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'postCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'lot'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'plan'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'titleReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'area'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'presentUse'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'lga'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'holdingType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Property
     */
    'isBuiltOn'?: boolean;
}
/**
 * 
 * @export
 * @interface PropertyNew
 */
export interface PropertyNew {
    /**
     * 
     * @type {string}
     * @memberof PropertyNew
     */
    'lot'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyNew
     */
    'plan'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyNew
     */
    'titleReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyNew
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyNew
     */
    'area'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyNew
     */
    'presentUse'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyNew
     */
    'lga'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyNew
     */
    'holdingType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyNew
     */
    'isBuiltOn'?: boolean;
}
/**
 * 
 * @export
 * @interface PutLicenceRequest
 */
export interface PutLicenceRequest {
    /**
     * 
     * @type {TenantContact}
     * @memberof PutLicenceRequest
     */
    'licence': TenantContact;
}
/**
 * 
 * @export
 * @interface PutMyDetailsRequest
 */
export interface PutMyDetailsRequest {
    /**
     * 
     * @type {Participant1}
     * @memberof PutMyDetailsRequest
     */
    'user': Participant1;
}
/**
 * 
 * @export
 * @interface RecipientSigningStatus
 */
export interface RecipientSigningStatus {
    /**
     * 
     * @type {string}
     * @memberof RecipientSigningStatus
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientSigningStatus
     */
    'statusDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientSigningStatus
     */
    'status'?: RecipientSigningStatusStatusEnum;
}

export const RecipientSigningStatusStatusEnum = {
    Created: 'created',
    Pending: 'pending',
    Sent: 'sent',
    Failed: 'failed',
    Completed: 'completed',
    Declined: 'declined'
} as const;

export type RecipientSigningStatusStatusEnum = typeof RecipientSigningStatusStatusEnum[keyof typeof RecipientSigningStatusStatusEnum];

/**
 * 
 * @export
 * @interface ReferralPartner
 */
export interface ReferralPartner {
    /**
     * 
     * @type {Participant1}
     * @memberof ReferralPartner
     */
    'partner'?: Participant1;
    /**
     * 
     * @type {number}
     * @memberof ReferralPartner
     */
    'priority'?: number;
}
/**
 * 
 * @export
 * @interface ReferralPartners
 */
export interface ReferralPartners {
    /**
     * 
     * @type {Array<ReferralPartnersItemsInner>}
     * @memberof ReferralPartners
     */
    'items'?: Array<ReferralPartnersItemsInner>;
}
/**
 * 
 * @export
 * @interface ReferralPartnersItemsInner
 */
export interface ReferralPartnersItemsInner {
    /**
     * 
     * @type {Participant1}
     * @memberof ReferralPartnersItemsInner
     */
    'partner'?: Participant1;
    /**
     * 
     * @type {number}
     * @memberof ReferralPartnersItemsInner
     */
    'priority'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Role = {
    Seller: 'seller',
    SellerAgent: 'sellerAgent',
    SellerSolicitor: 'sellerSolicitor',
    Buyer: 'buyer',
    BuyerSolicitor: 'buyerSolicitor',
    Licencee: 'licencee'
} as const;

export type Role = typeof Role[keyof typeof Role];


/**
 * 
 * @export
 * @interface State
 */
export interface State {
    /**
     * 
     * @type {string}
     * @memberof State
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof State
     */
    'abbreviation'?: string;
}
/**
 * 
 * @export
 * @interface Street
 */
export interface Street {
    /**
     * 
     * @type {string}
     * @memberof Street
     */
    'name'?: string;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredStreetType}
     * @memberof Street
     */
    'type'?: PhysicalAustralianAddressStructuredStreetType;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredStreetType}
     * @memberof Street
     */
    'suffix'?: PhysicalAustralianAddressStructuredStreetType;
}
/**
 * 
 * @export
 * @interface StructuredAddress
 */
export interface StructuredAddress {
    /**
     * 
     * @type {string}
     * @memberof StructuredAddress
     */
    'buildingName'?: string;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredLotNumber}
     * @memberof StructuredAddress
     */
    'lotNumber'?: PhysicalAustralianAddressStructuredLotNumber;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredFlat}
     * @memberof StructuredAddress
     */
    'flat'?: PhysicalAustralianAddressStructuredFlat;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredLotNumber}
     * @memberof StructuredAddress
     */
    'level'?: PhysicalAustralianAddressStructuredLotNumber;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredLotNumber}
     * @memberof StructuredAddress
     */
    'number'?: PhysicalAustralianAddressStructuredLotNumber;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredStreet}
     * @memberof StructuredAddress
     */
    'street'?: PhysicalAustralianAddressStructuredStreet;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredLocality}
     * @memberof StructuredAddress
     */
    'locality'?: PhysicalAustralianAddressStructuredLocality;
    /**
     * 
     * @type {PhysicalAustralianAddressStructuredState}
     * @memberof StructuredAddress
     */
    'state'?: PhysicalAustralianAddressStructuredState;
    /**
     * 
     * @type {string}
     * @memberof StructuredAddress
     */
    'postcode'?: string;
    /**
     * 
     * @type {number}
     * @memberof StructuredAddress
     */
    'confidence'?: number;
}
/**
 * 
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'customerID'?: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'subscriptionID'?: string;
}
/**
 * 
 * @export
 * @interface TeamInvitation
 */
export interface TeamInvitation {
    /**
     * 
     * @type {number}
     * @memberof TeamInvitation
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitation
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitation
     */
    'emailAddress'?: string;
}
/**
 * 
 * @export
 * @interface TeamParticipant
 */
export interface TeamParticipant {
    /**
     * 
     * @type {string}
     * @memberof TeamParticipant
     */
    'sub': string;
    /**
     * 
     * @type {Participant1}
     * @memberof TeamParticipant
     */
    'participant': Participant1;
}
/**
 * 
 * @export
 * @interface TeamParticipant1
 */
export interface TeamParticipant1 {
    /**
     * 
     * @type {string}
     * @memberof TeamParticipant1
     */
    'sub': string;
    /**
     * 
     * @type {Participant1}
     * @memberof TeamParticipant1
     */
    'participant': Participant1;
}
/**
 * 
 * @export
 * @interface TenantContact
 */
export interface TenantContact {
    /**
     * 
     * @type {Participant1}
     * @memberof TenantContact
     */
    'contact'?: Participant1;
    /**
     * 
     * @type {string}
     * @memberof TenantContact
     */
    'licenceeNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantContact
     */
    'licenceeExpiryDate'?: string;
}
/**
 * 
 * @export
 * @interface Title
 */
export interface Title {
    /**
     * 
     * @type {string}
     * @memberof Title
     */
    'titleReference'?: string;
}
/**
 * 
 * @export
 * @interface TitleSearchResponse
 */
export interface TitleSearchResponse {
    /**
     * 
     * @type {Array<Title>}
     * @memberof TitleSearchResponse
     */
    'items'?: Array<Title>;
}
/**
 * 
 * @export
 * @interface TypeCodeName
 */
export interface TypeCodeName {
    /**
     * 
     * @type {string}
     * @memberof TypeCodeName
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeCodeName
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface TypeCodeNameExtended
 */
export interface TypeCodeNameExtended {
    /**
     * 
     * @type {string}
     * @memberof TypeCodeNameExtended
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeCodeNameExtended
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface UpdatedBenefitToAgent
 */
export interface UpdatedBenefitToAgent {
    /**
     * 
     * @type {string}
     * @memberof UpdatedBenefitToAgent
     */
    'service'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatedBenefitToAgent
     */
    'source'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdatedBenefitToAgent
     */
    'estimatedAmount'?: number;
}
/**
 * 
 * @export
 * @interface UpdatedFee
 */
export interface UpdatedFee {
    /**
     * 
     * @type {string}
     * @memberof UpdatedFee
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatedFee
     */
    'whenPayable'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdatedFee
     */
    'estimatedAmount'?: number;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface Validation
 */
export interface Validation {
    /**
     * The ID of the entity failing validation.
     * @type {number}
     * @memberof Validation
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Validation
     */
    'objectType': string;
    /**
     * 
     * @type {string}
     * @memberof Validation
     */
    'reason': string;
}
/**
 * 
 * @export
 * @interface Validations
 */
export interface Validations {
    /**
     * 
     * @type {Array<ValidationsItemsInner>}
     * @memberof Validations
     */
    'items'?: Array<ValidationsItemsInner>;
}
/**
 * 
 * @export
 * @interface ValidationsItemsInner
 */
export interface ValidationsItemsInner {
    /**
     * The ID of the entity failing validation.
     * @type {number}
     * @memberof ValidationsItemsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ValidationsItemsInner
     */
    'objectType': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationsItemsInner
     */
    'reason': string;
}
/**
 * 
 * @export
 * @interface Workflow
 */
export interface Workflow {
    /**
     * The state of the workspace
     * @type {string}
     * @memberof Workflow
     */
    'state': string;
    /**
     * The sub state of the workspace
     * @type {string}
     * @memberof Workflow
     */
    'subState': string;
    /**
     * A label for  the current state of the workflow
     * @type {string}
     * @memberof Workflow
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     * @deprecated
     */
    'appointAgent': string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     * @deprecated
     */
    'contractPreparation': string;
    /**
     * 
     * @type {Array<WorkflowOffersInner>}
     * @memberof Workflow
     */
    'offers': Array<WorkflowOffersInner>;
}
/**
 * 
 * @export
 * @interface WorkflowOffersInner
 */
export interface WorkflowOffersInner {
    /**
     * 
     * @type {number}
     * @memberof WorkflowOffersInner
     */
    'offerId': number;
    /**
     * 
     * @type {string}
     * @memberof WorkflowOffersInner
     */
    'buyerState': WorkflowOffersInnerBuyerStateEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkflowOffersInner
     */
    'sellerState': WorkflowOffersInnerSellerStateEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkflowOffersInner
     */
    'state': WorkflowOffersInnerStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowOffersInner
     */
    'buyerSolicitorInvited': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowOffersInner
     */
    'buyerInvited': boolean;
}

export const WorkflowOffersInnerBuyerStateEnum = {
    SigningNotStarted: 'SigningNotStarted',
    SigningReady: 'SigningReady',
    SigningSentForSigning: 'SigningSentForSigning',
    SigningComplete: 'SigningComplete',
    SigningDeclined: 'SigningDeclined'
} as const;

export type WorkflowOffersInnerBuyerStateEnum = typeof WorkflowOffersInnerBuyerStateEnum[keyof typeof WorkflowOffersInnerBuyerStateEnum];
export const WorkflowOffersInnerSellerStateEnum = {
    SigningNotStarted: 'SigningNotStarted',
    SigningReady: 'SigningReady',
    SigningSentForSigning: 'SigningSentForSigning',
    SigningComplete: 'SigningComplete',
    SigningDeclined: 'SigningDeclined'
} as const;

export type WorkflowOffersInnerSellerStateEnum = typeof WorkflowOffersInnerSellerStateEnum[keyof typeof WorkflowOffersInnerSellerStateEnum];
export const WorkflowOffersInnerStateEnum = {
    OfferCreated: 'OfferCreated',
    OfferNegotiatingContract: 'OfferNegotiatingContract',
    OfferSigning: 'OfferSigning',
    OfferCancelled: 'OfferCancelled',
    OfferComplete: 'OfferComplete'
} as const;

export type WorkflowOffersInnerStateEnum = typeof WorkflowOffersInnerStateEnum[keyof typeof WorkflowOffersInnerStateEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const WorkflowStage = {
    AgentAppointment: 'stage-agent-appointment',
    ContractPreparation: 'stage-contract-preparation',
    Offers: 'stage-offers',
    OfferConsideration: 'stage-offer-consideration',
    ContractSigned: 'stage-contract-signed'
} as const;

export type WorkflowStage = typeof WorkflowStage[keyof typeof WorkflowStage];


/**
 * workspace
 * @export
 * @interface Workspace
 */
export interface Workspace {
    /**
     * 
     * @type {number}
     * @memberof Workspace
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    'role': WorkspaceRoleEnum;
}

export const WorkspaceRoleEnum = {
    Seller: 'seller',
    SellerAgent: 'sellerAgent',
    SellerSolicitor: 'sellerSolicitor',
    Buyer: 'buyer',
    BuyerSolicitor: 'buyerSolicitor',
    Licencee: 'licencee'
} as const;

export type WorkspaceRoleEnum = typeof WorkspaceRoleEnum[keyof typeof WorkspaceRoleEnum];

/**
 * 
 * @export
 * @interface WorkspaceDetails
 */
export interface WorkspaceDetails {
    /**
     * 
     * @type {Property}
     * @memberof WorkspaceDetails
     */
    'property': Property;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetails
     */
    'agentId': string;
}
/**
 * 
 * @export
 * @interface WorkspaceSummary
 */
export interface WorkspaceSummary {
    /**
     * 
     * @type {Metadata}
     * @memberof WorkspaceSummary
     */
    'metadata'?: Metadata;
    /**
     * 
     * @type {Workspace}
     * @memberof WorkspaceSummary
     */
    'workspace'?: Workspace;
    /**
     * 
     * @type {Property}
     * @memberof WorkspaceSummary
     */
    'property'?: Property;
}
/**
 * 
 * @export
 * @interface WorkspaceSummaryNew
 */
export interface WorkspaceSummaryNew {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceSummaryNew
     */
    'id': number;
    /**
     * 
     * @type {Property}
     * @memberof WorkspaceSummaryNew
     */
    'property': Property;
    /**
     * 
     * @type {Array<Participant1>}
     * @memberof WorkspaceSummaryNew
     */
    'sellers': Array<Participant1>;
    /**
     * 
     * @type {Participant1}
     * @memberof WorkspaceSummaryNew
     */
    'agent': Participant1;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceSummaryNew
     */
    'createdDate': string;
    /**
     * 
     * @type {Array<OfferSummary>}
     * @memberof WorkspaceSummaryNew
     */
    'offers'?: Array<OfferSummary>;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceSummaryNew
     */
    'role': WorkspaceSummaryNewRoleEnum;
}

export const WorkspaceSummaryNewRoleEnum = {
    Seller: 'seller',
    SellerAgent: 'sellerAgent',
    SellerSolicitor: 'sellerSolicitor',
    Buyer: 'buyer',
    BuyerSolicitor: 'buyerSolicitor',
    Licencee: 'licencee'
} as const;

export type WorkspaceSummaryNewRoleEnum = typeof WorkspaceSummaryNewRoleEnum[keyof typeof WorkspaceSummaryNewRoleEnum];

/**
 * 
 * @export
 * @interface Workspaces
 */
export interface Workspaces {
    /**
     * 
     * @type {Array<WorkspaceSummary>}
     * @memberof Workspaces
     */
    'items'?: Array<WorkspaceSummary>;
}
/**
 * 
 * @export
 * @interface Workspaces1
 */
export interface Workspaces1 {
    /**
     * 
     * @type {Array<WorkspaceSummaryNew>}
     * @memberof Workspaces1
     */
    'items'?: Array<WorkspaceSummaryNew>;
}

/**
 * AddressSearchApi - axios parameter creator
 * @export
 */
export const AddressSearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get an address details
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddress: async (addressId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('getAddress', 'addressId', addressId)
            const localVarPath = `/address-search/{addressId}`
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lookup an address
         * @param {string} addressSearchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAddress: async (addressSearchTerm: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressSearchTerm' is not null or undefined
            assertParamExists('searchAddress', 'addressSearchTerm', addressSearchTerm)
            const localVarPath = `/address-search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)

            if (addressSearchTerm !== undefined) {
                localVarQueryParameter['addressSearchTerm'] = addressSearchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddressSearchApi - functional programming interface
 * @export
 */
export const AddressSearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressSearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get an address details
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddress(addressId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhysicalAustralianAddress>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddress(addressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lookup an address
         * @param {string} addressSearchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAddress(addressSearchTerm: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlattenedAddresses>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAddress(addressSearchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AddressSearchApi - factory interface
 * @export
 */
export const AddressSearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddressSearchApiFp(configuration)
    return {
        /**
         * 
         * @summary get an address details
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddress(addressId: string, options?: any): AxiosPromise<PhysicalAustralianAddress> {
            return localVarFp.getAddress(addressId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lookup an address
         * @param {string} addressSearchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAddress(addressSearchTerm: string, options?: any): AxiosPromise<FlattenedAddresses> {
            return localVarFp.searchAddress(addressSearchTerm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddressSearchApi - object-oriented interface
 * @export
 * @class AddressSearchApi
 * @extends {BaseAPI}
 */
export class AddressSearchApi extends BaseAPI {
    /**
     * 
     * @summary get an address details
     * @param {string} addressId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressSearchApi
     */
    public getAddress(addressId: string, options?: AxiosRequestConfig) {
        return AddressSearchApiFp(this.configuration).getAddress(addressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lookup an address
     * @param {string} addressSearchTerm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressSearchApi
     */
    public searchAddress(addressSearchTerm: string, options?: AxiosRequestConfig) {
        return AddressSearchApiFp(this.configuration).searchAddress(addressSearchTerm, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AgentAppointmentApi - axios parameter creator
 * @export
 */
export const AgentAppointmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a BenefitToAgent
         * @param {number} workspaceId 
         * @param {number} benefitToAgentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBenefitToAgent: async (workspaceId: number, benefitToAgentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteBenefitToAgent', 'workspaceId', workspaceId)
            // verify required parameter 'benefitToAgentId' is not null or undefined
            assertParamExists('deleteBenefitToAgent', 'benefitToAgentId', benefitToAgentId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/benefitToAgent/{benefitToAgentId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"benefitToAgentId"}}`, encodeURIComponent(String(benefitToAgentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a fee
         * @param {number} workspaceId 
         * @param {number} feeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFee: async (workspaceId: number, feeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteFee', 'workspaceId', workspaceId)
            // verify required parameter 'feeId' is not null or undefined
            assertParamExists('deleteFee', 'feeId', feeId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/fees/{feeId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"feeId"}}`, encodeURIComponent(String(feeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get agent appointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointment: async (workspaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAgentAppointment', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the pdf for the appointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointmentPdf: async (workspaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAgentAppointmentPdf', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/pdf`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the agent appointment status
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointmentStatus: async (workspaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAgentAppointmentStatus', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/status`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of agent appointment validation erros.  These errors must be fixed before the agent appointment contract can be sent for signing. 
         * @summary get agent appointment validation errors
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointmentValidation: async (workspaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getAgentAppointmentValidation', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/validation`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a BenefitToAgent
         * @param {number} workspaceId 
         * @param {number} benefitToAgentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitToAgent: async (workspaceId: number, benefitToAgentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getBenefitToAgent', 'workspaceId', workspaceId)
            // verify required parameter 'benefitToAgentId' is not null or undefined
            assertParamExists('getBenefitToAgent', 'benefitToAgentId', benefitToAgentId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/benefitToAgent/{benefitToAgentId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"benefitToAgentId"}}`, encodeURIComponent(String(benefitToAgentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the BenefitToAgents associated with an agent aoppointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitToAgents: async (workspaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getBenefitToAgents', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/benefitToAgent`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a fee
         * @param {number} workspaceId 
         * @param {number} feeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFee: async (workspaceId: number, feeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getFee', 'workspaceId', workspaceId)
            // verify required parameter 'feeId' is not null or undefined
            assertParamExists('getFee', 'feeId', feeId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/fees/{feeId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"feeId"}}`, encodeURIComponent(String(feeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the fees associated with an agent aoppointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFees: async (workspaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getFees', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/fees`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a BenefitToAgent
         * @param {number} workspaceId 
         * @param {UpdatedBenefitToAgent} updatedBenefitToAgent Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBenefitToAgent: async (workspaceId: number, updatedBenefitToAgent: UpdatedBenefitToAgent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('postBenefitToAgent', 'workspaceId', workspaceId)
            // verify required parameter 'updatedBenefitToAgent' is not null or undefined
            assertParamExists('postBenefitToAgent', 'updatedBenefitToAgent', updatedBenefitToAgent)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/benefitToAgent`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatedBenefitToAgent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a fee
         * @param {number} workspaceId 
         * @param {UpdatedFee} updatedFee Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFee: async (workspaceId: number, updatedFee: UpdatedFee, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('postFee', 'workspaceId', workspaceId)
            // verify required parameter 'updatedFee' is not null or undefined
            assertParamExists('postFee', 'updatedFee', updatedFee)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/fees`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatedFee, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update an agent appointment
         * @param {number} workspaceId 
         * @param {AgentAppointment} agentAppointment AgentAppointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAgentAppointment: async (workspaceId: number, agentAppointment: AgentAppointment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putAgentAppointment', 'workspaceId', workspaceId)
            // verify required parameter 'agentAppointment' is not null or undefined
            assertParamExists('putAgentAppointment', 'agentAppointment', agentAppointment)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentAppointment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send the agent appointment for signing
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAgentAppointmentForSigning: async (workspaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('sendAgentAppointmentForSigning', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/send-for-signing`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a BenefitToAgent
         * @param {number} workspaceId 
         * @param {number} benefitToAgentId 
         * @param {UpdatedBenefitToAgent} updatedBenefitToAgent Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenefitToAgent: async (workspaceId: number, benefitToAgentId: number, updatedBenefitToAgent: UpdatedBenefitToAgent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateBenefitToAgent', 'workspaceId', workspaceId)
            // verify required parameter 'benefitToAgentId' is not null or undefined
            assertParamExists('updateBenefitToAgent', 'benefitToAgentId', benefitToAgentId)
            // verify required parameter 'updatedBenefitToAgent' is not null or undefined
            assertParamExists('updateBenefitToAgent', 'updatedBenefitToAgent', updatedBenefitToAgent)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/benefitToAgent/{benefitToAgentId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"benefitToAgentId"}}`, encodeURIComponent(String(benefitToAgentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatedBenefitToAgent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a fee
         * @param {number} workspaceId 
         * @param {number} feeId 
         * @param {UpdatedFee} updatedFee Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFee: async (workspaceId: number, feeId: number, updatedFee: UpdatedFee, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateFee', 'workspaceId', workspaceId)
            // verify required parameter 'feeId' is not null or undefined
            assertParamExists('updateFee', 'feeId', feeId)
            // verify required parameter 'updatedFee' is not null or undefined
            assertParamExists('updateFee', 'updatedFee', updatedFee)
            const localVarPath = `/workspaces/{workspaceId}/agent-appointment/fees/{feeId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"feeId"}}`, encodeURIComponent(String(feeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatedFee, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentAppointmentApi - functional programming interface
 * @export
 */
export const AgentAppointmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentAppointmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a BenefitToAgent
         * @param {number} workspaceId 
         * @param {number} benefitToAgentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBenefitToAgent(workspaceId: number, benefitToAgentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBenefitToAgent(workspaceId, benefitToAgentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a fee
         * @param {number} workspaceId 
         * @param {number} feeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFee(workspaceId: number, feeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFee(workspaceId, feeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get agent appointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentAppointment(workspaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentAppointment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentAppointment(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get the pdf for the appointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentAppointmentPdf(workspaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentAppointmentPdf(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the agent appointment status
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentAppointmentStatus(workspaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentAppointmentStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentAppointmentStatus(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of agent appointment validation erros.  These errors must be fixed before the agent appointment contract can be sent for signing. 
         * @summary get agent appointment validation errors
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentAppointmentValidation(workspaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Validations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentAppointmentValidation(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a BenefitToAgent
         * @param {number} workspaceId 
         * @param {number} benefitToAgentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenefitToAgent(workspaceId: number, benefitToAgentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenefitToAgent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenefitToAgent(workspaceId, benefitToAgentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the BenefitToAgents associated with an agent aoppointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenefitToAgents(workspaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenefitToAgents>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenefitToAgents(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a fee
         * @param {number} workspaceId 
         * @param {number} feeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFee(workspaceId: number, feeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Fee>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFee(workspaceId, feeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the fees associated with an agent aoppointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFees(workspaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Fees>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFees(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a BenefitToAgent
         * @param {number} workspaceId 
         * @param {UpdatedBenefitToAgent} updatedBenefitToAgent Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postBenefitToAgent(workspaceId: number, updatedBenefitToAgent: UpdatedBenefitToAgent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postBenefitToAgent(workspaceId, updatedBenefitToAgent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a fee
         * @param {number} workspaceId 
         * @param {UpdatedFee} updatedFee Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFee(workspaceId: number, updatedFee: UpdatedFee, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFee(workspaceId, updatedFee, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update an agent appointment
         * @param {number} workspaceId 
         * @param {AgentAppointment} agentAppointment AgentAppointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAgentAppointment(workspaceId: number, agentAppointment: AgentAppointment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAgentAppointment(workspaceId, agentAppointment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send the agent appointment for signing
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendAgentAppointmentForSigning(workspaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentAppointmentStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendAgentAppointmentForSigning(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a BenefitToAgent
         * @param {number} workspaceId 
         * @param {number} benefitToAgentId 
         * @param {UpdatedBenefitToAgent} updatedBenefitToAgent Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBenefitToAgent(workspaceId: number, benefitToAgentId: number, updatedBenefitToAgent: UpdatedBenefitToAgent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBenefitToAgent(workspaceId, benefitToAgentId, updatedBenefitToAgent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a fee
         * @param {number} workspaceId 
         * @param {number} feeId 
         * @param {UpdatedFee} updatedFee Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFee(workspaceId: number, feeId: number, updatedFee: UpdatedFee, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFee(workspaceId, feeId, updatedFee, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgentAppointmentApi - factory interface
 * @export
 */
export const AgentAppointmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentAppointmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a BenefitToAgent
         * @param {number} workspaceId 
         * @param {number} benefitToAgentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBenefitToAgent(workspaceId: number, benefitToAgentId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBenefitToAgent(workspaceId, benefitToAgentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a fee
         * @param {number} workspaceId 
         * @param {number} feeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFee(workspaceId: number, feeId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFee(workspaceId, feeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get agent appointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointment(workspaceId: number, options?: any): AxiosPromise<AgentAppointment> {
            return localVarFp.getAgentAppointment(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the pdf for the appointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointmentPdf(workspaceId: number, options?: any): AxiosPromise<File> {
            return localVarFp.getAgentAppointmentPdf(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the agent appointment status
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointmentStatus(workspaceId: number, options?: any): AxiosPromise<AgentAppointmentStatus> {
            return localVarFp.getAgentAppointmentStatus(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of agent appointment validation erros.  These errors must be fixed before the agent appointment contract can be sent for signing. 
         * @summary get agent appointment validation errors
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentAppointmentValidation(workspaceId: number, options?: any): AxiosPromise<Validations> {
            return localVarFp.getAgentAppointmentValidation(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a BenefitToAgent
         * @param {number} workspaceId 
         * @param {number} benefitToAgentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitToAgent(workspaceId: number, benefitToAgentId: number, options?: any): AxiosPromise<BenefitToAgent> {
            return localVarFp.getBenefitToAgent(workspaceId, benefitToAgentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the BenefitToAgents associated with an agent aoppointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitToAgents(workspaceId: number, options?: any): AxiosPromise<BenefitToAgents> {
            return localVarFp.getBenefitToAgents(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a fee
         * @param {number} workspaceId 
         * @param {number} feeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFee(workspaceId: number, feeId: number, options?: any): AxiosPromise<Fee> {
            return localVarFp.getFee(workspaceId, feeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the fees associated with an agent aoppointment
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFees(workspaceId: number, options?: any): AxiosPromise<Fees> {
            return localVarFp.getFees(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a BenefitToAgent
         * @param {number} workspaceId 
         * @param {UpdatedBenefitToAgent} updatedBenefitToAgent Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBenefitToAgent(workspaceId: number, updatedBenefitToAgent: UpdatedBenefitToAgent, options?: any): AxiosPromise<NewEntity> {
            return localVarFp.postBenefitToAgent(workspaceId, updatedBenefitToAgent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a fee
         * @param {number} workspaceId 
         * @param {UpdatedFee} updatedFee Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFee(workspaceId: number, updatedFee: UpdatedFee, options?: any): AxiosPromise<NewEntity> {
            return localVarFp.postFee(workspaceId, updatedFee, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update an agent appointment
         * @param {number} workspaceId 
         * @param {AgentAppointment} agentAppointment AgentAppointment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAgentAppointment(workspaceId: number, agentAppointment: AgentAppointment, options?: any): AxiosPromise<void> {
            return localVarFp.putAgentAppointment(workspaceId, agentAppointment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send the agent appointment for signing
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAgentAppointmentForSigning(workspaceId: number, options?: any): AxiosPromise<AgentAppointmentStatus> {
            return localVarFp.sendAgentAppointmentForSigning(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a BenefitToAgent
         * @param {number} workspaceId 
         * @param {number} benefitToAgentId 
         * @param {UpdatedBenefitToAgent} updatedBenefitToAgent Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenefitToAgent(workspaceId: number, benefitToAgentId: number, updatedBenefitToAgent: UpdatedBenefitToAgent, options?: any): AxiosPromise<void> {
            return localVarFp.updateBenefitToAgent(workspaceId, benefitToAgentId, updatedBenefitToAgent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a fee
         * @param {number} workspaceId 
         * @param {number} feeId 
         * @param {UpdatedFee} updatedFee Fee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFee(workspaceId: number, feeId: number, updatedFee: UpdatedFee, options?: any): AxiosPromise<void> {
            return localVarFp.updateFee(workspaceId, feeId, updatedFee, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentAppointmentApi - object-oriented interface
 * @export
 * @class AgentAppointmentApi
 * @extends {BaseAPI}
 */
export class AgentAppointmentApi extends BaseAPI {
    /**
     * 
     * @summary Delete a BenefitToAgent
     * @param {number} workspaceId 
     * @param {number} benefitToAgentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public deleteBenefitToAgent(workspaceId: number, benefitToAgentId: number, options?: AxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).deleteBenefitToAgent(workspaceId, benefitToAgentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a fee
     * @param {number} workspaceId 
     * @param {number} feeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public deleteFee(workspaceId: number, feeId: number, options?: AxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).deleteFee(workspaceId, feeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get agent appointment
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public getAgentAppointment(workspaceId: number, options?: AxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).getAgentAppointment(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the pdf for the appointment
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public getAgentAppointmentPdf(workspaceId: number, options?: AxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).getAgentAppointmentPdf(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the agent appointment status
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public getAgentAppointmentStatus(workspaceId: number, options?: AxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).getAgentAppointmentStatus(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of agent appointment validation erros.  These errors must be fixed before the agent appointment contract can be sent for signing. 
     * @summary get agent appointment validation errors
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public getAgentAppointmentValidation(workspaceId: number, options?: AxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).getAgentAppointmentValidation(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a BenefitToAgent
     * @param {number} workspaceId 
     * @param {number} benefitToAgentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public getBenefitToAgent(workspaceId: number, benefitToAgentId: number, options?: AxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).getBenefitToAgent(workspaceId, benefitToAgentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the BenefitToAgents associated with an agent aoppointment
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public getBenefitToAgents(workspaceId: number, options?: AxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).getBenefitToAgents(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a fee
     * @param {number} workspaceId 
     * @param {number} feeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public getFee(workspaceId: number, feeId: number, options?: AxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).getFee(workspaceId, feeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the fees associated with an agent aoppointment
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public getFees(workspaceId: number, options?: AxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).getFees(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a BenefitToAgent
     * @param {number} workspaceId 
     * @param {UpdatedBenefitToAgent} updatedBenefitToAgent Fee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public postBenefitToAgent(workspaceId: number, updatedBenefitToAgent: UpdatedBenefitToAgent, options?: AxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).postBenefitToAgent(workspaceId, updatedBenefitToAgent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a fee
     * @param {number} workspaceId 
     * @param {UpdatedFee} updatedFee Fee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public postFee(workspaceId: number, updatedFee: UpdatedFee, options?: AxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).postFee(workspaceId, updatedFee, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update an agent appointment
     * @param {number} workspaceId 
     * @param {AgentAppointment} agentAppointment AgentAppointment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public putAgentAppointment(workspaceId: number, agentAppointment: AgentAppointment, options?: AxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).putAgentAppointment(workspaceId, agentAppointment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send the agent appointment for signing
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public sendAgentAppointmentForSigning(workspaceId: number, options?: AxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).sendAgentAppointmentForSigning(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a BenefitToAgent
     * @param {number} workspaceId 
     * @param {number} benefitToAgentId 
     * @param {UpdatedBenefitToAgent} updatedBenefitToAgent Fee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public updateBenefitToAgent(workspaceId: number, benefitToAgentId: number, updatedBenefitToAgent: UpdatedBenefitToAgent, options?: AxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).updateBenefitToAgent(workspaceId, benefitToAgentId, updatedBenefitToAgent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a fee
     * @param {number} workspaceId 
     * @param {number} feeId 
     * @param {UpdatedFee} updatedFee Fee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentAppointmentApi
     */
    public updateFee(workspaceId: number, feeId: number, updatedFee: UpdatedFee, options?: AxiosRequestConfig) {
        return AgentAppointmentApiFp(this.configuration).updateFee(workspaceId, feeId, updatedFee, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ContractsApi - axios parameter creator
 * @export
 */
export const ContractsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add an annexure to the contract
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {AddAnnexureDocumentTypeEnum} documentType 
         * @param {File} body the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAnnexure: async (workspaceId: number, documentName: string, documentType: AddAnnexureDocumentTypeEnum, body: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('addAnnexure', 'workspaceId', workspaceId)
            // verify required parameter 'documentName' is not null or undefined
            assertParamExists('addAnnexure', 'documentName', documentName)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('addAnnexure', 'documentType', documentType)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addAnnexure', 'body', body)
            const localVarPath = `/workspaces/{workspaceId}/contract/annexures/add/{documentName}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"documentName"}}`, encodeURIComponent(String(documentName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * - publishes the draft contract to the document list of the workspace - moves the worklfow from `contract-preparation-drafting` to `contract-preparation-disclosures`. 
         * @summary complete contract drafting
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeContractDrafting: async (workspaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('completeContractDrafting', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/contract/complete`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the annexure from the contract 
         * @summary delete annexure
         * @param {number} workspaceId 
         * @param {number} annexureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnexure: async (workspaceId: number, annexureId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteAnnexure', 'workspaceId', workspaceId)
            // verify required parameter 'annexureId' is not null or undefined
            assertParamExists('deleteAnnexure', 'annexureId', annexureId)
            const localVarPath = `/workspaces/{workspaceId}/contract/annexures/{annexureId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"annexureId"}}`, encodeURIComponent(String(annexureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get contract
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract: async (workspaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getContract', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/contract`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get contract annexures
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractAnnexures: async (workspaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getContractAnnexures', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/contract/annexures`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the contract as a PDF
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdf: async (workspaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getPdf', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/contract/pdf`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary invite a solicitor to prepare contract disclosures
         * @param {number} workspaceId 
         * @param {Invitation} invitation SellerSolicitorInvitationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteSellerSolicitor: async (workspaceId: number, invitation: Invitation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('inviteSellerSolicitor', 'workspaceId', workspaceId)
            // verify required parameter 'invitation' is not null or undefined
            assertParamExists('inviteSellerSolicitor', 'invitation', invitation)
            const localVarPath = `/workspaces/{workspaceId}/contract/invite-seller-solicitor`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invitation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a contract
         * @param {number} workspaceId 
         * @param {Contract} contract Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContract: async (workspaceId: number, contract: Contract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putContract', 'workspaceId', workspaceId)
            // verify required parameter 'contract' is not null or undefined
            assertParamExists('putContract', 'contract', contract)
            const localVarPath = `/workspaces/{workspaceId}/contract`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the rendering order of the annexure in the contract 
         * @summary change annexure order in contract
         * @param {number} workspaceId 
         * @param {number} annexureId 
         * @param {number} position 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnexurePosition: async (workspaceId: number, annexureId: number, position: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('updateAnnexurePosition', 'workspaceId', workspaceId)
            // verify required parameter 'annexureId' is not null or undefined
            assertParamExists('updateAnnexurePosition', 'annexureId', annexureId)
            // verify required parameter 'position' is not null or undefined
            assertParamExists('updateAnnexurePosition', 'position', position)
            const localVarPath = `/workspaces/{workspaceId}/contract/annexures/{annexureId}/position/{position}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"annexureId"}}`, encodeURIComponent(String(annexureId)))
                .replace(`{${"position"}}`, encodeURIComponent(String(position)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractsApi - functional programming interface
 * @export
 */
export const ContractsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary add an annexure to the contract
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {AddAnnexureDocumentTypeEnum} documentType 
         * @param {File} body the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAnnexure(workspaceId: number, documentName: string, documentType: AddAnnexureDocumentTypeEnum, body: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAnnexure(workspaceId, documentName, documentType, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * - publishes the draft contract to the document list of the workspace - moves the worklfow from `contract-preparation-drafting` to `contract-preparation-disclosures`. 
         * @summary complete contract drafting
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeContractDrafting(workspaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeContractDrafting(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the annexure from the contract 
         * @summary delete annexure
         * @param {number} workspaceId 
         * @param {number} annexureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnnexure(workspaceId: number, annexureId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnnexure(workspaceId, annexureId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get contract
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContract(workspaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContract(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get contract annexures
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractAnnexures(workspaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractAnnexuresResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractAnnexures(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get the contract as a PDF
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPdf(workspaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPdf(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary invite a solicitor to prepare contract disclosures
         * @param {number} workspaceId 
         * @param {Invitation} invitation SellerSolicitorInvitationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteSellerSolicitor(workspaceId: number, invitation: Invitation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteSellerSolicitor(workspaceId, invitation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a contract
         * @param {number} workspaceId 
         * @param {Contract} contract Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putContract(workspaceId: number, contract: Contract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putContract(workspaceId, contract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the rendering order of the annexure in the contract 
         * @summary change annexure order in contract
         * @param {number} workspaceId 
         * @param {number} annexureId 
         * @param {number} position 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnnexurePosition(workspaceId: number, annexureId: number, position: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnnexurePosition(workspaceId, annexureId, position, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractsApi - factory interface
 * @export
 */
export const ContractsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractsApiFp(configuration)
    return {
        /**
         * 
         * @summary add an annexure to the contract
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {AddAnnexureDocumentTypeEnum} documentType 
         * @param {File} body the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAnnexure(workspaceId: number, documentName: string, documentType: AddAnnexureDocumentTypeEnum, body: File, options?: any): AxiosPromise<void> {
            return localVarFp.addAnnexure(workspaceId, documentName, documentType, body, options).then((request) => request(axios, basePath));
        },
        /**
         * - publishes the draft contract to the document list of the workspace - moves the worklfow from `contract-preparation-drafting` to `contract-preparation-disclosures`. 
         * @summary complete contract drafting
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeContractDrafting(workspaceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.completeContractDrafting(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the annexure from the contract 
         * @summary delete annexure
         * @param {number} workspaceId 
         * @param {number} annexureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnexure(workspaceId: number, annexureId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAnnexure(workspaceId, annexureId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get contract
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract(workspaceId: number, options?: any): AxiosPromise<Contract> {
            return localVarFp.getContract(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get contract annexures
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractAnnexures(workspaceId: number, options?: any): AxiosPromise<ContractAnnexuresResponse> {
            return localVarFp.getContractAnnexures(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the contract as a PDF
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdf(workspaceId: number, options?: any): AxiosPromise<File> {
            return localVarFp.getPdf(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary invite a solicitor to prepare contract disclosures
         * @param {number} workspaceId 
         * @param {Invitation} invitation SellerSolicitorInvitationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteSellerSolicitor(workspaceId: number, invitation: Invitation, options?: any): AxiosPromise<void> {
            return localVarFp.inviteSellerSolicitor(workspaceId, invitation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a contract
         * @param {number} workspaceId 
         * @param {Contract} contract Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContract(workspaceId: number, contract: Contract, options?: any): AxiosPromise<void> {
            return localVarFp.putContract(workspaceId, contract, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the rendering order of the annexure in the contract 
         * @summary change annexure order in contract
         * @param {number} workspaceId 
         * @param {number} annexureId 
         * @param {number} position 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnexurePosition(workspaceId: number, annexureId: number, position: number, options?: any): AxiosPromise<void> {
            return localVarFp.updateAnnexurePosition(workspaceId, annexureId, position, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractsApi - object-oriented interface
 * @export
 * @class ContractsApi
 * @extends {BaseAPI}
 */
export class ContractsApi extends BaseAPI {
    /**
     * 
     * @summary add an annexure to the contract
     * @param {number} workspaceId 
     * @param {string} documentName 
     * @param {AddAnnexureDocumentTypeEnum} documentType 
     * @param {File} body the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public addAnnexure(workspaceId: number, documentName: string, documentType: AddAnnexureDocumentTypeEnum, body: File, options?: AxiosRequestConfig) {
        return ContractsApiFp(this.configuration).addAnnexure(workspaceId, documentName, documentType, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * - publishes the draft contract to the document list of the workspace - moves the worklfow from `contract-preparation-drafting` to `contract-preparation-disclosures`. 
     * @summary complete contract drafting
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public completeContractDrafting(workspaceId: number, options?: AxiosRequestConfig) {
        return ContractsApiFp(this.configuration).completeContractDrafting(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the annexure from the contract 
     * @summary delete annexure
     * @param {number} workspaceId 
     * @param {number} annexureId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public deleteAnnexure(workspaceId: number, annexureId: number, options?: AxiosRequestConfig) {
        return ContractsApiFp(this.configuration).deleteAnnexure(workspaceId, annexureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get contract
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public getContract(workspaceId: number, options?: AxiosRequestConfig) {
        return ContractsApiFp(this.configuration).getContract(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get contract annexures
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public getContractAnnexures(workspaceId: number, options?: AxiosRequestConfig) {
        return ContractsApiFp(this.configuration).getContractAnnexures(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the contract as a PDF
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public getPdf(workspaceId: number, options?: AxiosRequestConfig) {
        return ContractsApiFp(this.configuration).getPdf(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary invite a solicitor to prepare contract disclosures
     * @param {number} workspaceId 
     * @param {Invitation} invitation SellerSolicitorInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public inviteSellerSolicitor(workspaceId: number, invitation: Invitation, options?: AxiosRequestConfig) {
        return ContractsApiFp(this.configuration).inviteSellerSolicitor(workspaceId, invitation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a contract
     * @param {number} workspaceId 
     * @param {Contract} contract Contract
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public putContract(workspaceId: number, contract: Contract, options?: AxiosRequestConfig) {
        return ContractsApiFp(this.configuration).putContract(workspaceId, contract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the rendering order of the annexure in the contract 
     * @summary change annexure order in contract
     * @param {number} workspaceId 
     * @param {number} annexureId 
     * @param {number} position 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public updateAnnexurePosition(workspaceId: number, annexureId: number, position: number, options?: AxiosRequestConfig) {
        return ContractsApiFp(this.configuration).updateAnnexurePosition(workspaceId, annexureId, position, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AddAnnexureDocumentTypeEnum = {
    AgentAppointment: 'AgentAppointment',
    ContractOfSale: 'ContractOfSale',
    Annexure: 'Annexure'
} as const;
export type AddAnnexureDocumentTypeEnum = typeof AddAnnexureDocumentTypeEnum[keyof typeof AddAnnexureDocumentTypeEnum];


/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a document
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument: async (workspaceId: number, documentName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDocument', 'workspaceId', workspaceId)
            // verify required parameter 'documentName' is not null or undefined
            assertParamExists('getDocument', 'documentName', documentName)
            const localVarPath = `/workspaces/{workspaceId}/documents/{documentName}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"documentName"}}`, encodeURIComponent(String(documentName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the documents in a workspace
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments: async (workspaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getDocuments', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/documents`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put a document
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {PutDocumentDocumentTypeEnum} documentType 
         * @param {File} body the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDocument: async (workspaceId: number, documentName: string, documentType: PutDocumentDocumentTypeEnum, body: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putDocument', 'workspaceId', workspaceId)
            // verify required parameter 'documentName' is not null or undefined
            assertParamExists('putDocument', 'documentName', documentName)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('putDocument', 'documentType', documentType)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('putDocument', 'body', body)
            const localVarPath = `/workspaces/{workspaceId}/documents/{documentName}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"documentName"}}`, encodeURIComponent(String(documentName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a document
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocument(workspaceId: number, documentName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(workspaceId, documentName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the documents in a workspace
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocuments(workspaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocuments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocuments(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put a document
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {PutDocumentDocumentTypeEnum} documentType 
         * @param {File} body the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDocument(workspaceId: number, documentName: string, documentType: PutDocumentDocumentTypeEnum, body: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDocument(workspaceId, documentName, documentType, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a document
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument(workspaceId: number, documentName: string, options?: any): AxiosPromise<File> {
            return localVarFp.getDocument(workspaceId, documentName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the documents in a workspace
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments(workspaceId: number, options?: any): AxiosPromise<GetDocuments200Response> {
            return localVarFp.getDocuments(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put a document
         * @param {number} workspaceId 
         * @param {string} documentName 
         * @param {PutDocumentDocumentTypeEnum} documentType 
         * @param {File} body the document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDocument(workspaceId: number, documentName: string, documentType: PutDocumentDocumentTypeEnum, body: File, options?: any): AxiosPromise<void> {
            return localVarFp.putDocument(workspaceId, documentName, documentType, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
    /**
     * 
     * @summary Get a document
     * @param {number} workspaceId 
     * @param {string} documentName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getDocument(workspaceId: number, documentName: string, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getDocument(workspaceId, documentName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the documents in a workspace
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getDocuments(workspaceId: number, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getDocuments(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put a document
     * @param {number} workspaceId 
     * @param {string} documentName 
     * @param {PutDocumentDocumentTypeEnum} documentType 
     * @param {File} body the document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public putDocument(workspaceId: number, documentName: string, documentType: PutDocumentDocumentTypeEnum, body: File, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).putDocument(workspaceId, documentName, documentType, body, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const PutDocumentDocumentTypeEnum = {
    AgentAppointment: 'AgentAppointment',
    ContractOfSale: 'ContractOfSale',
    Annexure: 'Annexure'
} as const;
export type PutDocumentDocumentTypeEnum = typeof PutDocumentDocumentTypeEnum[keyof typeof PutDocumentDocumentTypeEnum];


/**
 * OffersApi - axios parameter creator
 * @export
 */
export const OffersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary accept offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptOffer: async (workspaceId: number, offerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('acceptOffer', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('acceptOffer', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/accept`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary singal that the seller or buyer has finished amending the contract
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractReady: async (workspaceId: number, offerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('contractReady', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('contractReady', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/contract-ready`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOffer: async (workspaceId: number, offerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteOffer', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('deleteOffer', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary remove a participant from the contract
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfferParticipant: async (workspaceId: number, offerId: number, participantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteOfferParticipant', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('deleteOfferParticipant', 'offerId', offerId)
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('deleteOfferParticipant', 'participantId', participantId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/participants/{participantId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)))
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffer: async (workspaceId: number, offerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getOffer', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('getOffer', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get draft of the offer contract
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferDraft: async (workspaceId: number, offerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getOfferDraft', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('getOfferDraft', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/draft`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary contract as a PDF
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferDraftPDF: async (workspaceId: number, offerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getOfferDraftPDF', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('getOfferDraftPDF', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/pdf`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get an offer participant
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferParticipant: async (workspaceId: number, offerId: number, participantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getOfferParticipant', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('getOfferParticipant', 'offerId', offerId)
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('getOfferParticipant', 'participantId', participantId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/participants/{participantId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)))
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get offer participants
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferParticipants: async (workspaceId: number, offerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getOfferParticipants', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('getOfferParticipants', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/participants`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the offers that have been created by the `sellerAgent` on behalf of `buyers`. If  the `role` type of the user is `buyer`, only the offers that have been shared with the `buyer` will be visible. 
         * @summary get offers
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffers: async (workspaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getOffers', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/offers`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Buyers are invited to the workspace so they can view the contract and other documents. 
         * @summary starts the offer workflow.
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteBuyers: async (workspaceId: number, offerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('inviteBuyers', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('inviteBuyers', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/invite-buyers`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create an offer
         * @param {number} workspaceId 
         * @param {Contract} contract Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOffer: async (workspaceId: number, contract: Contract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('postOffer', 'workspaceId', workspaceId)
            // verify required parameter 'contract' is not null or undefined
            assertParamExists('postOffer', 'contract', contract)
            const localVarPath = `/workspaces/{workspaceId}/offers`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary add a participant
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {Participant1} participant1 ParticipantDetails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOfferParticipant: async (workspaceId: number, offerId: number, participant1: Participant1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('postOfferParticipant', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('postOfferParticipant', 'offerId', offerId)
            // verify required parameter 'participant1' is not null or undefined
            assertParamExists('postOfferParticipant', 'participant1', participant1)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/participants`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participant1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Presenting an offer makes the offer visible to the seller.  The seller will be notified by email that the offer is ready for viewing in the workspace. 
         * @summary Present offer to seller
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        presentOfferToSeller: async (workspaceId: number, offerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('presentOfferToSeller', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('presentOfferToSeller', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/present`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {Contract} contract Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOffer: async (workspaceId: number, offerId: number, contract: Contract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putOffer', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('putOffer', 'offerId', offerId)
            // verify required parameter 'contract' is not null or undefined
            assertParamExists('putOffer', 'contract', contract)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a participant
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {number} participantId 
         * @param {Participant1} participant1 Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOfferParticipant: async (workspaceId: number, offerId: number, participantId: number, participant1: Participant1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putOfferParticipant', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('putOfferParticipant', 'offerId', offerId)
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('putOfferParticipant', 'participantId', participantId)
            // verify required parameter 'participant1' is not null or undefined
            assertParamExists('putOfferParticipant', 'participant1', participant1)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/participants/{participantId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)))
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participant1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary send to buyer for signing
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendToBuyerForSigning: async (workspaceId: number, offerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('sendToBuyerForSigning', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('sendToBuyerForSigning', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/send-to-buyer-for-signing`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary send to seller for signing
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendToSellerForSigning: async (workspaceId: number, offerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('sendToSellerForSigning', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('sendToSellerForSigning', 'offerId', offerId)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/send-to-seller-for-signing`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OffersApi - functional programming interface
 * @export
 */
export const OffersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OffersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary accept offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptOffer(workspaceId: number, offerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptOffer(workspaceId, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary singal that the seller or buyer has finished amending the contract
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractReady(workspaceId: number, offerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractReady(workspaceId, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOffer(workspaceId: number, offerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOffer(workspaceId, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary remove a participant from the contract
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOfferParticipant(workspaceId: number, offerId: number, participantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOfferParticipant(workspaceId, offerId, participantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOffer(workspaceId: number, offerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOffer(workspaceId, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get draft of the offer contract
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferDraft(workspaceId: number, offerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferDraft(workspaceId, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary contract as a PDF
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferDraftPDF(workspaceId: number, offerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferDraftPDF(workspaceId, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get an offer participant
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferParticipant(workspaceId: number, offerId: number, participantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Participant1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferParticipant(workspaceId, offerId, participantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get offer participants
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferParticipants(workspaceId: number, offerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Participants>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferParticipants(workspaceId, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the offers that have been created by the `sellerAgent` on behalf of `buyers`. If  the `role` type of the user is `buyer`, only the offers that have been shared with the `buyer` will be visible. 
         * @summary get offers
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOffers(workspaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOffers(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Buyers are invited to the workspace so they can view the contract and other documents. 
         * @summary starts the offer workflow.
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteBuyers(workspaceId: number, offerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteBuyers(workspaceId, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create an offer
         * @param {number} workspaceId 
         * @param {Contract} contract Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOffer(workspaceId: number, contract: Contract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOffer(workspaceId, contract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary add a participant
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {Participant1} participant1 ParticipantDetails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOfferParticipant(workspaceId: number, offerId: number, participant1: Participant1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOfferParticipant(workspaceId, offerId, participant1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Presenting an offer makes the offer visible to the seller.  The seller will be notified by email that the offer is ready for viewing in the workspace. 
         * @summary Present offer to seller
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async presentOfferToSeller(workspaceId: number, offerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.presentOfferToSeller(workspaceId, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {Contract} contract Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putOffer(workspaceId: number, offerId: number, contract: Contract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putOffer(workspaceId, offerId, contract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a participant
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {number} participantId 
         * @param {Participant1} participant1 Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putOfferParticipant(workspaceId: number, offerId: number, participantId: number, participant1: Participant1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putOfferParticipant(workspaceId, offerId, participantId, participant1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary send to buyer for signing
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendToBuyerForSigning(workspaceId: number, offerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendToBuyerForSigning(workspaceId, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary send to seller for signing
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendToSellerForSigning(workspaceId: number, offerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendToSellerForSigning(workspaceId, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OffersApi - factory interface
 * @export
 */
export const OffersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OffersApiFp(configuration)
    return {
        /**
         * 
         * @summary accept offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptOffer(workspaceId: number, offerId: number, options?: any): AxiosPromise<void> {
            return localVarFp.acceptOffer(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary singal that the seller or buyer has finished amending the contract
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractReady(workspaceId: number, offerId: number, options?: any): AxiosPromise<void> {
            return localVarFp.contractReady(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOffer(workspaceId: number, offerId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOffer(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary remove a participant from the contract
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfferParticipant(workspaceId: number, offerId: number, participantId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOfferParticipant(workspaceId, offerId, participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffer(workspaceId: number, offerId: number, options?: any): AxiosPromise<Contract> {
            return localVarFp.getOffer(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get draft of the offer contract
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferDraft(workspaceId: number, offerId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getOfferDraft(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary contract as a PDF
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferDraftPDF(workspaceId: number, offerId: number, options?: any): AxiosPromise<File> {
            return localVarFp.getOfferDraftPDF(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get an offer participant
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferParticipant(workspaceId: number, offerId: number, participantId: number, options?: any): AxiosPromise<Participant1> {
            return localVarFp.getOfferParticipant(workspaceId, offerId, participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get offer participants
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferParticipants(workspaceId: number, offerId: number, options?: any): AxiosPromise<Participants> {
            return localVarFp.getOfferParticipants(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the offers that have been created by the `sellerAgent` on behalf of `buyers`. If  the `role` type of the user is `buyer`, only the offers that have been shared with the `buyer` will be visible. 
         * @summary get offers
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffers(workspaceId: number, options?: any): AxiosPromise<Offers> {
            return localVarFp.getOffers(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Buyers are invited to the workspace so they can view the contract and other documents. 
         * @summary starts the offer workflow.
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteBuyers(workspaceId: number, offerId: number, options?: any): AxiosPromise<void> {
            return localVarFp.inviteBuyers(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create an offer
         * @param {number} workspaceId 
         * @param {Contract} contract Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOffer(workspaceId: number, contract: Contract, options?: any): AxiosPromise<NewEntity> {
            return localVarFp.postOffer(workspaceId, contract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary add a participant
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {Participant1} participant1 ParticipantDetails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOfferParticipant(workspaceId: number, offerId: number, participant1: Participant1, options?: any): AxiosPromise<NewEntity> {
            return localVarFp.postOfferParticipant(workspaceId, offerId, participant1, options).then((request) => request(axios, basePath));
        },
        /**
         * Presenting an offer makes the offer visible to the seller.  The seller will be notified by email that the offer is ready for viewing in the workspace. 
         * @summary Present offer to seller
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        presentOfferToSeller(workspaceId: number, offerId: number, options?: any): AxiosPromise<void> {
            return localVarFp.presentOfferToSeller(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update offer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {Contract} contract Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOffer(workspaceId: number, offerId: number, contract: Contract, options?: any): AxiosPromise<Contract> {
            return localVarFp.putOffer(workspaceId, offerId, contract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a participant
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {number} participantId 
         * @param {Participant1} participant1 Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOfferParticipant(workspaceId: number, offerId: number, participantId: number, participant1: Participant1, options?: any): AxiosPromise<void> {
            return localVarFp.putOfferParticipant(workspaceId, offerId, participantId, participant1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary send to buyer for signing
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendToBuyerForSigning(workspaceId: number, offerId: number, options?: any): AxiosPromise<void> {
            return localVarFp.sendToBuyerForSigning(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary send to seller for signing
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendToSellerForSigning(workspaceId: number, offerId: number, options?: any): AxiosPromise<void> {
            return localVarFp.sendToSellerForSigning(workspaceId, offerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OffersApi - object-oriented interface
 * @export
 * @class OffersApi
 * @extends {BaseAPI}
 */
export class OffersApi extends BaseAPI {
    /**
     * 
     * @summary accept offer
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public acceptOffer(workspaceId: number, offerId: number, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).acceptOffer(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary singal that the seller or buyer has finished amending the contract
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public contractReady(workspaceId: number, offerId: number, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).contractReady(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete offer
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public deleteOffer(workspaceId: number, offerId: number, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).deleteOffer(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary remove a participant from the contract
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {number} participantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public deleteOfferParticipant(workspaceId: number, offerId: number, participantId: number, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).deleteOfferParticipant(workspaceId, offerId, participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get offer
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public getOffer(workspaceId: number, offerId: number, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).getOffer(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get draft of the offer contract
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public getOfferDraft(workspaceId: number, offerId: number, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).getOfferDraft(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary contract as a PDF
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public getOfferDraftPDF(workspaceId: number, offerId: number, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).getOfferDraftPDF(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get an offer participant
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {number} participantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public getOfferParticipant(workspaceId: number, offerId: number, participantId: number, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).getOfferParticipant(workspaceId, offerId, participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get offer participants
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public getOfferParticipants(workspaceId: number, offerId: number, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).getOfferParticipants(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the offers that have been created by the `sellerAgent` on behalf of `buyers`. If  the `role` type of the user is `buyer`, only the offers that have been shared with the `buyer` will be visible. 
     * @summary get offers
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public getOffers(workspaceId: number, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).getOffers(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Buyers are invited to the workspace so they can view the contract and other documents. 
     * @summary starts the offer workflow.
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public inviteBuyers(workspaceId: number, offerId: number, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).inviteBuyers(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create an offer
     * @param {number} workspaceId 
     * @param {Contract} contract Offer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public postOffer(workspaceId: number, contract: Contract, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).postOffer(workspaceId, contract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary add a participant
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {Participant1} participant1 ParticipantDetails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public postOfferParticipant(workspaceId: number, offerId: number, participant1: Participant1, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).postOfferParticipant(workspaceId, offerId, participant1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Presenting an offer makes the offer visible to the seller.  The seller will be notified by email that the offer is ready for viewing in the workspace. 
     * @summary Present offer to seller
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public presentOfferToSeller(workspaceId: number, offerId: number, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).presentOfferToSeller(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update offer
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {Contract} contract Offer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public putOffer(workspaceId: number, offerId: number, contract: Contract, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).putOffer(workspaceId, offerId, contract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a participant
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {number} participantId 
     * @param {Participant1} participant1 Offer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public putOfferParticipant(workspaceId: number, offerId: number, participantId: number, participant1: Participant1, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).putOfferParticipant(workspaceId, offerId, participantId, participant1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary send to buyer for signing
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public sendToBuyerForSigning(workspaceId: number, offerId: number, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).sendToBuyerForSigning(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary send to seller for signing
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffersApi
     */
    public sendToSellerForSigning(workspaceId: number, offerId: number, options?: AxiosRequestConfig) {
        return OffersApiFp(this.configuration).sendToSellerForSigning(workspaceId, offerId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ParticipantsApi - axios parameter creator
 * @export
 */
export const ParticipantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary remove a participant from the contract
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParticipant: async (workspaceId: number, participantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteParticipant', 'workspaceId', workspaceId)
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('deleteParticipant', 'participantId', participantId)
            const localVarPath = `/workspaces/{workspaceId}/participants/{participantId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a participantcontracts
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipant: async (workspaceId: number, participantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getParticipant', 'workspaceId', workspaceId)
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('getParticipant', 'participantId', participantId)
            const localVarPath = `/workspaces/{workspaceId}/participants/{participantId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get participants
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipants: async (workspaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getParticipants', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/participants`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary add a participant
         * @param {number} workspaceId 
         * @param {Participant1} participant1 ParticipantDetails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postParticipant: async (workspaceId: number, participant1: Participant1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('postParticipant', 'workspaceId', workspaceId)
            // verify required parameter 'participant1' is not null or undefined
            assertParamExists('postParticipant', 'participant1', participant1)
            const localVarPath = `/workspaces/{workspaceId}/participants`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participant1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a participant
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {Participant1} participant1 Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParticipant: async (workspaceId: number, participantId: number, participant1: Participant1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putParticipant', 'workspaceId', workspaceId)
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('putParticipant', 'participantId', participantId)
            // verify required parameter 'participant1' is not null or undefined
            assertParamExists('putParticipant', 'participant1', participant1)
            const localVarPath = `/workspaces/{workspaceId}/participants/{participantId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participant1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParticipantsApi - functional programming interface
 * @export
 */
export const ParticipantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParticipantsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary remove a participant from the contract
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteParticipant(workspaceId: number, participantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteParticipant(workspaceId, participantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a participantcontracts
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticipant(workspaceId: number, participantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Participant1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParticipant(workspaceId, participantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get participants
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticipants(workspaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Participants>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParticipants(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary add a participant
         * @param {number} workspaceId 
         * @param {Participant1} participant1 ParticipantDetails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postParticipant(workspaceId: number, participant1: Participant1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postParticipant(workspaceId, participant1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a participant
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {Participant1} participant1 Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putParticipant(workspaceId: number, participantId: number, participant1: Participant1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putParticipant(workspaceId, participantId, participant1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParticipantsApi - factory interface
 * @export
 */
export const ParticipantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParticipantsApiFp(configuration)
    return {
        /**
         * 
         * @summary remove a participant from the contract
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParticipant(workspaceId: number, participantId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteParticipant(workspaceId, participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a participantcontracts
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipant(workspaceId: number, participantId: number, options?: any): AxiosPromise<Participant1> {
            return localVarFp.getParticipant(workspaceId, participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get participants
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipants(workspaceId: number, options?: any): AxiosPromise<Participants> {
            return localVarFp.getParticipants(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary add a participant
         * @param {number} workspaceId 
         * @param {Participant1} participant1 ParticipantDetails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postParticipant(workspaceId: number, participant1: Participant1, options?: any): AxiosPromise<NewEntity> {
            return localVarFp.postParticipant(workspaceId, participant1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a participant
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {Participant1} participant1 Offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParticipant(workspaceId: number, participantId: number, participant1: Participant1, options?: any): AxiosPromise<void> {
            return localVarFp.putParticipant(workspaceId, participantId, participant1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParticipantsApi - object-oriented interface
 * @export
 * @class ParticipantsApi
 * @extends {BaseAPI}
 */
export class ParticipantsApi extends BaseAPI {
    /**
     * 
     * @summary remove a participant from the contract
     * @param {number} workspaceId 
     * @param {number} participantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApi
     */
    public deleteParticipant(workspaceId: number, participantId: number, options?: AxiosRequestConfig) {
        return ParticipantsApiFp(this.configuration).deleteParticipant(workspaceId, participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a participantcontracts
     * @param {number} workspaceId 
     * @param {number} participantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApi
     */
    public getParticipant(workspaceId: number, participantId: number, options?: AxiosRequestConfig) {
        return ParticipantsApiFp(this.configuration).getParticipant(workspaceId, participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get participants
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApi
     */
    public getParticipants(workspaceId: number, options?: AxiosRequestConfig) {
        return ParticipantsApiFp(this.configuration).getParticipants(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary add a participant
     * @param {number} workspaceId 
     * @param {Participant1} participant1 ParticipantDetails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApi
     */
    public postParticipant(workspaceId: number, participant1: Participant1, options?: AxiosRequestConfig) {
        return ParticipantsApiFp(this.configuration).postParticipant(workspaceId, participant1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a participant
     * @param {number} workspaceId 
     * @param {number} participantId 
     * @param {Participant1} participant1 Offer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApi
     */
    public putParticipant(workspaceId: number, participantId: number, participant1: Participant1, options?: AxiosRequestConfig) {
        return ParticipantsApiFp(this.configuration).putParticipant(workspaceId, participantId, participant1, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PropertyApi - axios parameter creator
 * @export
 */
export const PropertyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get property
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperty: async (workspaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getProperty', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/property`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a property
         * @param {number} workspaceId 
         * @param {PropertyNew} propertyNew PutPropertyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProperty: async (workspaceId: number, propertyNew: PropertyNew, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putProperty', 'workspaceId', workspaceId)
            // verify required parameter 'propertyNew' is not null or undefined
            assertParamExists('putProperty', 'propertyNew', propertyNew)
            const localVarPath = `/workspaces/{workspaceId}/property`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(propertyNew, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyApi - functional programming interface
 * @export
 */
export const PropertyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get property
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProperty(workspaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Property>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProperty(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a property
         * @param {number} workspaceId 
         * @param {PropertyNew} propertyNew PutPropertyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putProperty(workspaceId: number, propertyNew: PropertyNew, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putProperty(workspaceId, propertyNew, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PropertyApi - factory interface
 * @export
 */
export const PropertyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertyApiFp(configuration)
    return {
        /**
         * 
         * @summary get property
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperty(workspaceId: number, options?: any): AxiosPromise<Property> {
            return localVarFp.getProperty(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a property
         * @param {number} workspaceId 
         * @param {PropertyNew} propertyNew PutPropertyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProperty(workspaceId: number, propertyNew: PropertyNew, options?: any): AxiosPromise<void> {
            return localVarFp.putProperty(workspaceId, propertyNew, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropertyApi - object-oriented interface
 * @export
 * @class PropertyApi
 * @extends {BaseAPI}
 */
export class PropertyApi extends BaseAPI {
    /**
     * 
     * @summary get property
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public getProperty(workspaceId: number, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).getProperty(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a property
     * @param {number} workspaceId 
     * @param {PropertyNew} propertyNew PutPropertyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public putProperty(workspaceId: number, propertyNew: PropertyNew, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).putProperty(workspaceId, propertyNew, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StatusApi - axios parameter creator
 * @export
 */
export const StatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the status of the service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusApi - functional programming interface
 * @export
 */
export const StatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the status of the service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatusApi - factory interface
 * @export
 */
export const StatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatusApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the status of the service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus(options?: any): AxiosPromise<void> {
            return localVarFp.getStatus(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusApi - object-oriented interface
 * @export
 * @class StatusApi
 * @extends {BaseAPI}
 */
export class StatusApi extends BaseAPI {
    /**
     * 
     * @summary Get the status of the service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public getStatus(options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).getStatus(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SubscriptionsApi - axios parameter creator
 * @export
 */
export const SubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary complete the subscription process
         * @param {CompleteSubscriptionRequest} [completeSubscriptionRequest] completeSubscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeSubscription: async (completeSubscriptionRequest?: CompleteSubscriptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscriptions/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeSubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary start the subscription process
         * @param {Participant1} [participant1] subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSubscription: async (participant1?: Participant1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscriptions/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participant1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionsApi - functional programming interface
 * @export
 */
export const SubscriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary complete the subscription process
         * @param {CompleteSubscriptionRequest} [completeSubscriptionRequest] completeSubscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeSubscription(completeSubscriptionRequest?: CompleteSubscriptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeSubscription(completeSubscriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary start the subscription process
         * @param {Participant1} [participant1] subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startSubscription(participant1?: Participant1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startSubscription(participant1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionsApi - factory interface
 * @export
 */
export const SubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionsApiFp(configuration)
    return {
        /**
         * 
         * @summary complete the subscription process
         * @param {CompleteSubscriptionRequest} [completeSubscriptionRequest] completeSubscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeSubscription(completeSubscriptionRequest?: CompleteSubscriptionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.completeSubscription(completeSubscriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary start the subscription process
         * @param {Participant1} [participant1] subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSubscription(participant1?: Participant1, options?: any): AxiosPromise<CreateSubscriptionResponse> {
            return localVarFp.startSubscription(participant1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionsApi - object-oriented interface
 * @export
 * @class SubscriptionsApi
 * @extends {BaseAPI}
 */
export class SubscriptionsApi extends BaseAPI {
    /**
     * 
     * @summary complete the subscription process
     * @param {CompleteSubscriptionRequest} [completeSubscriptionRequest] completeSubscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public completeSubscription(completeSubscriptionRequest?: CompleteSubscriptionRequest, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).completeSubscription(completeSubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary start the subscription process
     * @param {Participant1} [participant1] subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public startSubscription(participant1?: Participant1, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).startSubscription(participant1, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TeamApi - axios parameter creator
 * @export
 */
export const TeamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Accepting a team inviation will adds you to the team. 
         * @summary accept a team inviation
         * @param {number} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptTeamInvitation: async (invitationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationId' is not null or undefined
            assertParamExists('acceptTeamInvitation', 'invitationId', invitationId)
            const localVarPath = `/team/users/invitations/{invitationId}`
                .replace(`{${"invitationId"}}`, encodeURIComponent(String(invitationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Accepting a team inviation will adds you to the team. 
         * @summary cancel a team inviation
         * @param {number} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInvitation: async (invitationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationId' is not null or undefined
            assertParamExists('cancelInvitation', 'invitationId', invitationId)
            const localVarPath = `/team/users/invitations/{invitationId}`
                .replace(`{${"invitationId"}}`, encodeURIComponent(String(invitationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * dete referral partner
         * @summary delete referral partner
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReferralPartner: async (emailAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('deleteReferralPartner', 'emailAddress', emailAddress)
            const localVarPath = `/team/referral-partners/{emailAddress}`
                .replace(`{${"emailAddress"}}`, encodeURIComponent(String(emailAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Licencee details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicencee: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/licencee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current users details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyDetails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/my-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get referral partner
         * @summary get referral partner
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralPartner: async (emailAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('getReferralPartner', 'emailAddress', emailAddress)
            const localVarPath = `/team/referral-partners/{emailAddress}`
                .replace(`{${"emailAddress"}}`, encodeURIComponent(String(emailAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get referral partners
         * @summary get referral partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralPartners: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/referral-partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get team information
         * @summary get team information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeam: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the users in the same organisation as the current user. 
         * @summary get the invitations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamInvitations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/users/invitations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the users in the same organisation as the current user. 
         * @summary get the users in my organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds the user to the team. If the users does not yet have an account, an invitation  will be sent to the email address. 
         * @summary invite user to team
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserToTeam: async (emailAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('inviteUserToTeam', 'emailAddress', emailAddress)
            const localVarPath = `/team/users/invitations/invite/{emailAddress}`
                .replace(`{${"emailAddress"}}`, encodeURIComponent(String(emailAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add a referral partner
         * @summary add a referral partner
         * @param {ReferralPartnersItemsInner} [referralPartnersItemsInner] referral partner\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReferralPartner: async (referralPartnersItemsInner?: ReferralPartnersItemsInner, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/referral-partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(referralPartnersItemsInner, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the current users licencee
         * @param {PutLicenceRequest} [putLicenceRequest] details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLicencee: async (putLicenceRequest?: PutLicenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/licencee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putLicenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the current users details
         * @param {PutMyDetailsRequest} [putMyDetailsRequest] details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMyDetails: async (putMyDetailsRequest?: PutMyDetailsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/my-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putMyDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update a referral partner
         * @summary update a referral partner
         * @param {string} emailAddress 
         * @param {PutMyDetailsRequest} [putMyDetailsRequest] details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReferralPartner: async (emailAddress: string, putMyDetailsRequest?: PutMyDetailsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('putReferralPartner', 'emailAddress', emailAddress)
            const localVarPath = `/team/referral-partners/{emailAddress}`
                .replace(`{${"emailAddress"}}`, encodeURIComponent(String(emailAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putMyDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update team information
         * @summary update team information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTeam: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove a user 
         * @summary remove a user from team
         * @param {string} sub 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromTeam: async (sub: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sub' is not null or undefined
            assertParamExists('removeUserFromTeam', 'sub', sub)
            const localVarPath = `/team/users/{sub}`
                .replace(`{${"sub"}}`, encodeURIComponent(String(sub)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a user 
         * @summary update a user\'s details
         * @param {string} sub 
         * @param {PutMyDetailsRequest} [putMyDetailsRequest] user details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (sub: string, putMyDetailsRequest?: PutMyDetailsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sub' is not null or undefined
            assertParamExists('updateUser', 'sub', sub)
            const localVarPath = `/team/users/{sub}`
                .replace(`{${"sub"}}`, encodeURIComponent(String(sub)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putMyDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamApi - functional programming interface
 * @export
 */
export const TeamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamApiAxiosParamCreator(configuration)
    return {
        /**
         * Accepting a team inviation will adds you to the team. 
         * @summary accept a team inviation
         * @param {number} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptTeamInvitation(invitationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptTeamInvitation(invitationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Accepting a team inviation will adds you to the team. 
         * @summary cancel a team inviation
         * @param {number} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelInvitation(invitationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelInvitation(invitationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * dete referral partner
         * @summary delete referral partner
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReferralPartner(emailAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralPartnersItemsInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReferralPartner(emailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Licencee details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicencee(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLicenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicencee(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the current users details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyDetails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyDetails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get referral partner
         * @summary get referral partner
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralPartner(emailAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralPartnersItemsInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralPartner(emailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get referral partners
         * @summary get referral partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralPartners(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralPartners>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralPartners(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get team information
         * @summary get team information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeam(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeam(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all the users in the same organisation as the current user. 
         * @summary get the invitations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamInvitations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTeamInvitationsResposne>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamInvitations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all the users in the same organisation as the current user. 
         * @summary get the users in my organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Adds the user to the team. If the users does not yet have an account, an invitation  will be sent to the email address. 
         * @summary invite user to team
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteUserToTeam(emailAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteUserToTeam(emailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * add a referral partner
         * @summary add a referral partner
         * @param {ReferralPartnersItemsInner} [referralPartnersItemsInner] referral partner\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReferralPartner(referralPartnersItemsInner?: ReferralPartnersItemsInner, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReferralPartner(referralPartnersItemsInner, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update the current users licencee
         * @param {PutLicenceRequest} [putLicenceRequest] details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLicencee(putLicenceRequest?: PutLicenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putLicencee(putLicenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update the current users details
         * @param {PutMyDetailsRequest} [putMyDetailsRequest] details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMyDetails(putMyDetailsRequest?: PutMyDetailsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMyDetails(putMyDetailsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update a referral partner
         * @summary update a referral partner
         * @param {string} emailAddress 
         * @param {PutMyDetailsRequest} [putMyDetailsRequest] details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReferralPartner(emailAddress: string, putMyDetailsRequest?: PutMyDetailsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralPartnersItemsInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReferralPartner(emailAddress, putMyDetailsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update team information
         * @summary update team information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTeam(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTeam(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove a user 
         * @summary remove a user from team
         * @param {string} sub 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUserFromTeam(sub: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUserFromTeam(sub, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a user 
         * @summary update a user\'s details
         * @param {string} sub 
         * @param {PutMyDetailsRequest} [putMyDetailsRequest] user details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(sub: string, putMyDetailsRequest?: PutMyDetailsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(sub, putMyDetailsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamApi - factory interface
 * @export
 */
export const TeamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamApiFp(configuration)
    return {
        /**
         * Accepting a team inviation will adds you to the team. 
         * @summary accept a team inviation
         * @param {number} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptTeamInvitation(invitationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.acceptTeamInvitation(invitationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Accepting a team inviation will adds you to the team. 
         * @summary cancel a team inviation
         * @param {number} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInvitation(invitationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.cancelInvitation(invitationId, options).then((request) => request(axios, basePath));
        },
        /**
         * dete referral partner
         * @summary delete referral partner
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReferralPartner(emailAddress: string, options?: any): AxiosPromise<ReferralPartnersItemsInner> {
            return localVarFp.deleteReferralPartner(emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Licencee details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicencee(options?: any): AxiosPromise<GetLicenceResponse> {
            return localVarFp.getLicencee(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the current users details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyDetails(options?: any): AxiosPromise<GetMyDetailsResponse> {
            return localVarFp.getMyDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * get referral partner
         * @summary get referral partner
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralPartner(emailAddress: string, options?: any): AxiosPromise<ReferralPartnersItemsInner> {
            return localVarFp.getReferralPartner(emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * get referral partners
         * @summary get referral partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralPartners(options?: any): AxiosPromise<ReferralPartners> {
            return localVarFp.getReferralPartners(options).then((request) => request(axios, basePath));
        },
        /**
         * get team information
         * @summary get team information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeam(options?: any): AxiosPromise<Team> {
            return localVarFp.getTeam(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the users in the same organisation as the current user. 
         * @summary get the invitations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamInvitations(options?: any): AxiosPromise<GetTeamInvitationsResposne> {
            return localVarFp.getTeamInvitations(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the users in the same organisation as the current user. 
         * @summary get the users in my organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<GetUsersResponse> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * Adds the user to the team. If the users does not yet have an account, an invitation  will be sent to the email address. 
         * @summary invite user to team
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserToTeam(emailAddress: string, options?: any): AxiosPromise<void> {
            return localVarFp.inviteUserToTeam(emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * add a referral partner
         * @summary add a referral partner
         * @param {ReferralPartnersItemsInner} [referralPartnersItemsInner] referral partner\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReferralPartner(referralPartnersItemsInner?: ReferralPartnersItemsInner, options?: any): AxiosPromise<void> {
            return localVarFp.postReferralPartner(referralPartnersItemsInner, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the current users licencee
         * @param {PutLicenceRequest} [putLicenceRequest] details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLicencee(putLicenceRequest?: PutLicenceRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putLicencee(putLicenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the current users details
         * @param {PutMyDetailsRequest} [putMyDetailsRequest] details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMyDetails(putMyDetailsRequest?: PutMyDetailsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putMyDetails(putMyDetailsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * update a referral partner
         * @summary update a referral partner
         * @param {string} emailAddress 
         * @param {PutMyDetailsRequest} [putMyDetailsRequest] details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReferralPartner(emailAddress: string, putMyDetailsRequest?: PutMyDetailsRequest, options?: any): AxiosPromise<ReferralPartnersItemsInner> {
            return localVarFp.putReferralPartner(emailAddress, putMyDetailsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * update team information
         * @summary update team information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTeam(options?: any): AxiosPromise<void> {
            return localVarFp.putTeam(options).then((request) => request(axios, basePath));
        },
        /**
         * Remove a user 
         * @summary remove a user from team
         * @param {string} sub 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromTeam(sub: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeUserFromTeam(sub, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a user 
         * @summary update a user\'s details
         * @param {string} sub 
         * @param {PutMyDetailsRequest} [putMyDetailsRequest] user details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(sub: string, putMyDetailsRequest?: PutMyDetailsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateUser(sub, putMyDetailsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamApi - object-oriented interface
 * @export
 * @class TeamApi
 * @extends {BaseAPI}
 */
export class TeamApi extends BaseAPI {
    /**
     * Accepting a team inviation will adds you to the team. 
     * @summary accept a team inviation
     * @param {number} invitationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public acceptTeamInvitation(invitationId: number, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).acceptTeamInvitation(invitationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Accepting a team inviation will adds you to the team. 
     * @summary cancel a team inviation
     * @param {number} invitationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public cancelInvitation(invitationId: number, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).cancelInvitation(invitationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * dete referral partner
     * @summary delete referral partner
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public deleteReferralPartner(emailAddress: string, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).deleteReferralPartner(emailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Licencee details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getLicencee(options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).getLicencee(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the current users details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getMyDetails(options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).getMyDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get referral partner
     * @summary get referral partner
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getReferralPartner(emailAddress: string, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).getReferralPartner(emailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get referral partners
     * @summary get referral partners
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getReferralPartners(options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).getReferralPartners(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get team information
     * @summary get team information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getTeam(options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).getTeam(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the users in the same organisation as the current user. 
     * @summary get the invitations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getTeamInvitations(options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).getTeamInvitations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the users in the same organisation as the current user. 
     * @summary get the users in my organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getUsers(options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds the user to the team. If the users does not yet have an account, an invitation  will be sent to the email address. 
     * @summary invite user to team
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public inviteUserToTeam(emailAddress: string, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).inviteUserToTeam(emailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add a referral partner
     * @summary add a referral partner
     * @param {ReferralPartnersItemsInner} [referralPartnersItemsInner] referral partner\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public postReferralPartner(referralPartnersItemsInner?: ReferralPartnersItemsInner, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).postReferralPartner(referralPartnersItemsInner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the current users licencee
     * @param {PutLicenceRequest} [putLicenceRequest] details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public putLicencee(putLicenceRequest?: PutLicenceRequest, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).putLicencee(putLicenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the current users details
     * @param {PutMyDetailsRequest} [putMyDetailsRequest] details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public putMyDetails(putMyDetailsRequest?: PutMyDetailsRequest, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).putMyDetails(putMyDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update a referral partner
     * @summary update a referral partner
     * @param {string} emailAddress 
     * @param {PutMyDetailsRequest} [putMyDetailsRequest] details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public putReferralPartner(emailAddress: string, putMyDetailsRequest?: PutMyDetailsRequest, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).putReferralPartner(emailAddress, putMyDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update team information
     * @summary update team information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public putTeam(options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).putTeam(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove a user 
     * @summary remove a user from team
     * @param {string} sub 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public removeUserFromTeam(sub: string, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).removeUserFromTeam(sub, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a user 
     * @summary update a user\'s details
     * @param {string} sub 
     * @param {PutMyDetailsRequest} [putMyDetailsRequest] user details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public updateUser(sub: string, putMyDetailsRequest?: PutMyDetailsRequest, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).updateUser(sub, putMyDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary assert user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assertUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary assert user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assertUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssertUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assertUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary assert user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assertUser(options?: any): AxiosPromise<AssertUserResponse> {
            return localVarFp.assertUser(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary assert user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public assertUser(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).assertUser(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Envelope completed
         * @param {EnvelopeChangedRequest} envelopeChangedRequest the envelope update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        envelopeChanged: async (envelopeChangedRequest: EnvelopeChangedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'envelopeChangedRequest' is not null or undefined
            assertParamExists('envelopeChanged', 'envelopeChangedRequest', envelopeChangedRequest)
            const localVarPath = `/webhooks/envelope`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Annature required
            await setApiKeyToObject(localVarHeaderParameter, "X-Annature-Signature", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(envelopeChangedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Envelope completed
         * @param {EnvelopeChangedRequest} envelopeChangedRequest the envelope update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async envelopeChanged(envelopeChangedRequest: EnvelopeChangedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.envelopeChanged(envelopeChangedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhooksApiFp(configuration)
    return {
        /**
         * 
         * @summary Envelope completed
         * @param {EnvelopeChangedRequest} envelopeChangedRequest the envelope update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        envelopeChanged(envelopeChangedRequest: EnvelopeChangedRequest, options?: any): AxiosPromise<void> {
            return localVarFp.envelopeChanged(envelopeChangedRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
    /**
     * 
     * @summary Envelope completed
     * @param {EnvelopeChangedRequest} envelopeChangedRequest the envelope update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public envelopeChanged(envelopeChangedRequest: EnvelopeChangedRequest, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).envelopeChanged(envelopeChangedRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WorkspacesApi - axios parameter creator
 * @export
 */
export const WorkspacesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary accept an invitation to a workspace
         * @param {string} invitationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitation: async (invitationToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationToken' is not null or undefined
            assertParamExists('acceptInvitation', 'invitationToken', invitationToken)
            const localVarPath = `/accept-invitation/{invitationToken}`
                .replace(`{${"invitationToken"}}`, encodeURIComponent(String(invitationToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the activity log for the workspace. The list of events will differ depending on the user\'s role in the workspace. 
         * @summary Get the acivity log
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLog: async (workspaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getActivityLog', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/activity-log`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get workflow
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflow: async (workspaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkflow', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/workflow`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get workspace
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspace: async (workspaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspace', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get workspaces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaces: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/workspaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get workspaces2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaces2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/workspaces2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary invite someone to a workspace
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invite: async (workspaceId: number, participantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('invite', 'workspaceId', workspaceId)
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('invite', 'participantId', participantId)
            const localVarPath = `/workspaces/{workspaceId}/invite-user/{participantId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary invite a solicitor to represent the buyer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {Invitation} invitation BuyerSolicitorInvitationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteBuyerSolicitor: async (workspaceId: number, offerId: number, invitation: Invitation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('inviteBuyerSolicitor', 'workspaceId', workspaceId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('inviteBuyerSolicitor', 'offerId', offerId)
            // verify required parameter 'invitation' is not null or undefined
            assertParamExists('inviteBuyerSolicitor', 'invitation', invitation)
            const localVarPath = `/workspaces/{workspaceId}/offers/{offerId}/invite-buyer-solicitor`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invitation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary perform a task.
         * @param {number} workspaceId 
         * @param {{ [key: string]: string; }} requestBody PerformTaskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performTask: async (workspaceId: number, requestBody: { [key: string]: string; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('performTask', 'workspaceId', workspaceId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('performTask', 'requestBody', requestBody)
            const localVarPath = `/workspaces/{workspaceId}/workflow`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a new workspace
         * @param {WorkspaceDetails} workspaceDetails Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkspace: async (workspaceDetails: WorkspaceDetails, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceDetails' is not null or undefined
            assertParamExists('postWorkspace', 'workspaceDetails', workspaceDetails)
            const localVarPath = `/workspaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspaceDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for a title
         * @param {number} workspaceId 
         * @param {string} lotNumber 
         * @param {string} planNumber 
         * @param {TitleSearchPlanTypeEnum} planType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        titleSearch: async (workspaceId: number, lotNumber: string, planNumber: string, planType: TitleSearchPlanTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('titleSearch', 'workspaceId', workspaceId)
            // verify required parameter 'lotNumber' is not null or undefined
            assertParamExists('titleSearch', 'lotNumber', lotNumber)
            // verify required parameter 'planNumber' is not null or undefined
            assertParamExists('titleSearch', 'planNumber', planNumber)
            // verify required parameter 'planType' is not null or undefined
            assertParamExists('titleSearch', 'planType', planType)
            const localVarPath = `/workspaces/{workspaceId}/title-search`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["read"], configuration)

            if (lotNumber !== undefined) {
                localVarQueryParameter['lotNumber'] = lotNumber;
            }

            if (planNumber !== undefined) {
                localVarQueryParameter['planNumber'] = planNumber;
            }

            if (planType !== undefined) {
                localVarQueryParameter['planType'] = planType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspacesApi - functional programming interface
 * @export
 */
export const WorkspacesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkspacesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary accept an invitation to a workspace
         * @param {string} invitationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptInvitation(invitationToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptInvitation(invitationToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the activity log for the workspace. The list of events will differ depending on the user\'s role in the workspace. 
         * @summary Get the acivity log
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityLog(workspaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityLog(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get workflow
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflow(workspaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workflow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflow(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get workspace
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspace(workspaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspace(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get workspaces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaces(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workspaces>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaces(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get workspaces2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaces2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workspaces1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaces2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary invite someone to a workspace
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invite(workspaceId: number, participantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invite(workspaceId, participantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary invite a solicitor to represent the buyer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {Invitation} invitation BuyerSolicitorInvitationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteBuyerSolicitor(workspaceId: number, offerId: number, invitation: Invitation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteBuyerSolicitor(workspaceId, offerId, invitation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary perform a task.
         * @param {number} workspaceId 
         * @param {{ [key: string]: string; }} requestBody PerformTaskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performTask(workspaceId: number, requestBody: { [key: string]: string; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.performTask(workspaceId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create a new workspace
         * @param {WorkspaceDetails} workspaceDetails Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWorkspace(workspaceDetails: WorkspaceDetails, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWorkspace(workspaceDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for a title
         * @param {number} workspaceId 
         * @param {string} lotNumber 
         * @param {string} planNumber 
         * @param {TitleSearchPlanTypeEnum} planType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async titleSearch(workspaceId: number, lotNumber: string, planNumber: string, planType: TitleSearchPlanTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TitleSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.titleSearch(workspaceId, lotNumber, planNumber, planType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkspacesApi - factory interface
 * @export
 */
export const WorkspacesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkspacesApiFp(configuration)
    return {
        /**
         * 
         * @summary accept an invitation to a workspace
         * @param {string} invitationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitation(invitationToken: string, options?: any): AxiosPromise<void> {
            return localVarFp.acceptInvitation(invitationToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the activity log for the workspace. The list of events will differ depending on the user\'s role in the workspace. 
         * @summary Get the acivity log
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLog(workspaceId: number, options?: any): AxiosPromise<ActivityLogResponse> {
            return localVarFp.getActivityLog(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get workflow
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflow(workspaceId: number, options?: any): AxiosPromise<Workflow> {
            return localVarFp.getWorkflow(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get workspace
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspace(workspaceId: number, options?: any): AxiosPromise<WorkspaceSummary> {
            return localVarFp.getWorkspace(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get workspaces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaces(options?: any): AxiosPromise<Workspaces> {
            return localVarFp.getWorkspaces(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get workspaces2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaces2(options?: any): AxiosPromise<Workspaces1> {
            return localVarFp.getWorkspaces2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary invite someone to a workspace
         * @param {number} workspaceId 
         * @param {number} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invite(workspaceId: number, participantId: number, options?: any): AxiosPromise<void> {
            return localVarFp.invite(workspaceId, participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary invite a solicitor to represent the buyer
         * @param {number} workspaceId 
         * @param {number} offerId 
         * @param {Invitation} invitation BuyerSolicitorInvitationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteBuyerSolicitor(workspaceId: number, offerId: number, invitation: Invitation, options?: any): AxiosPromise<void> {
            return localVarFp.inviteBuyerSolicitor(workspaceId, offerId, invitation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary perform a task.
         * @param {number} workspaceId 
         * @param {{ [key: string]: string; }} requestBody PerformTaskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performTask(workspaceId: number, requestBody: { [key: string]: string; }, options?: any): AxiosPromise<void> {
            return localVarFp.performTask(workspaceId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a new workspace
         * @param {WorkspaceDetails} workspaceDetails Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkspace(workspaceDetails: WorkspaceDetails, options?: any): AxiosPromise<NewEntity> {
            return localVarFp.postWorkspace(workspaceDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for a title
         * @param {number} workspaceId 
         * @param {string} lotNumber 
         * @param {string} planNumber 
         * @param {TitleSearchPlanTypeEnum} planType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        titleSearch(workspaceId: number, lotNumber: string, planNumber: string, planType: TitleSearchPlanTypeEnum, options?: any): AxiosPromise<TitleSearchResponse> {
            return localVarFp.titleSearch(workspaceId, lotNumber, planNumber, planType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkspacesApi - object-oriented interface
 * @export
 * @class WorkspacesApi
 * @extends {BaseAPI}
 */
export class WorkspacesApi extends BaseAPI {
    /**
     * 
     * @summary accept an invitation to a workspace
     * @param {string} invitationToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public acceptInvitation(invitationToken: string, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).acceptInvitation(invitationToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the activity log for the workspace. The list of events will differ depending on the user\'s role in the workspace. 
     * @summary Get the acivity log
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getActivityLog(workspaceId: number, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).getActivityLog(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get workflow
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getWorkflow(workspaceId: number, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).getWorkflow(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get workspace
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getWorkspace(workspaceId: number, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).getWorkspace(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get workspaces
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getWorkspaces(options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).getWorkspaces(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get workspaces2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getWorkspaces2(options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).getWorkspaces2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary invite someone to a workspace
     * @param {number} workspaceId 
     * @param {number} participantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public invite(workspaceId: number, participantId: number, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).invite(workspaceId, participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary invite a solicitor to represent the buyer
     * @param {number} workspaceId 
     * @param {number} offerId 
     * @param {Invitation} invitation BuyerSolicitorInvitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public inviteBuyerSolicitor(workspaceId: number, offerId: number, invitation: Invitation, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).inviteBuyerSolicitor(workspaceId, offerId, invitation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary perform a task.
     * @param {number} workspaceId 
     * @param {{ [key: string]: string; }} requestBody PerformTaskRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public performTask(workspaceId: number, requestBody: { [key: string]: string; }, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).performTask(workspaceId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a new workspace
     * @param {WorkspaceDetails} workspaceDetails Contract
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public postWorkspace(workspaceDetails: WorkspaceDetails, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).postWorkspace(workspaceDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for a title
     * @param {number} workspaceId 
     * @param {string} lotNumber 
     * @param {string} planNumber 
     * @param {TitleSearchPlanTypeEnum} planType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public titleSearch(workspaceId: number, lotNumber: string, planNumber: string, planType: TitleSearchPlanTypeEnum, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).titleSearch(workspaceId, lotNumber, planNumber, planType, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const TitleSearchPlanTypeEnum = {
    Bpa: 'BPA',
    Brp: 'BRP',
    Bup: 'BUP',
    Cp: 'CP',
    Grp: 'GRP',
    Gta: 'GTA',
    Gtp: 'GTP',
    Lb: 'LB',
    Mcp: 'MCP',
    Mpp: 'MPP',
    Msp: 'MSP',
    Rp: 'RP',
    Sba: 'SBA',
    Sbp: 'SBP',
    Sp: 'SP',
    Spa: 'SPA',
    Sps: 'SPS',
    Srp: 'SRP',
    Ssp: 'SSP',
    Ub: 'UB'
} as const;
export type TitleSearchPlanTypeEnum = typeof TitleSearchPlanTypeEnum[keyof typeof TitleSearchPlanTypeEnum];


