import { FC, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  CreateWorkspaceStatus,
  createWorkspaceState,
  initial,
} from "./create-workspace-slice";
import {
  Autocomplete,
  Box,
  Typography,
  Chip,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import { createWorkspace, getTenancyUsers, searchByAddress } from "./thunks";
import { useNavigate } from "react-router-dom";
import { User } from "../../generated";
import LCButton from "../../components/button";
import { TitleSearchResult } from "../../conveyancing-documents-generated";
import React from "react";

export interface CreateWorkspaceProps {
  onClose: () => void;
}

const CreateWorkspacePage: FC<CreateWorkspaceProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(createWorkspaceState);
  const navigate = useNavigate();

  const steps = ["Property", "Agent", "Review"];

  const [address, setAddress] = useState<string>("");
  const [selectedAddress, setSelectedAddress] = useState<string>("");
  const [selectedAgent, setSelectedAgent] = useState<User | null>(null);
  const [addressError, setAddressError] = useState<string | null>(null);
  const [agentError, setAgentError] = useState<string | null>(null);
  const [propertyDetails, setPropertyDetails] = useState<any | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);

  useEffect(() => {
    if (state.status === CreateWorkspaceStatus.initial) {
      dispatch(getTenancyUsers());
    }
    if (state.status === CreateWorkspaceStatus.worspaceCreated) {
      navigate(`/workspaces/${state.newWorkspaceId}`);
      dispatch(initial());
      onClose();
    }
  }, [state.status, dispatch]);

  useEffect(() => {
    if (state.addressSearchResult?.length) {
      setIsAutocompleteOpen(true);
    } else {
      setIsAutocompleteOpen(false);
    }
  }, [state.addressSearchResult]);

  const handleAddressChange = (
    _event: any,
    selectedOption: TitleSearchResult | null
  ): void => {
    if (selectedOption) {
      setSelectedAddress(selectedOption.address);
      setPropertyDetails({
        address: selectedOption.address,
        description: selectedOption.description,
        lotOnplan: selectedOption.lotOnPlan,
        ownerName: selectedOption.ownerName,
        reference: selectedOption.reference,
        status: selectedOption.status,
      });
    } else {
      setSelectedAddress("");
      setPropertyDetails(null);
    }
    setAddressError(null);
    setIsAutocompleteOpen(false);
  };

  const handleAgentChange = (_event: any, agent: User | null): void => {
    setSelectedAgent(agent);
    setAgentError(null);
  };

  const handleNext = () => {
    if (activeStep === 0 && !selectedAddress) {
      setAddressError("Address is required.");
      return;
    }
    if (activeStep === 1 && !selectedAgent) {
      setAgentError("Agent is required.");
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const Property = () => (
    <>
      <Typography variant="h6" my={2}>
        Search for a Property
      </Typography>
      <Box sx={{ display: "flex", margin: "15px 0", alignItems: "center" }}>
        <TextField
          fullWidth
          label="Address"
          size="small"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          error={!!addressError}
          helperText={addressError}
        />
        <Box width={120} ml={2}>
          <LCButton
            label="Search"
            color="black"
            size="small"
            onClick={() => {
              dispatch(searchByAddress(address));
              setSelectedAddress("");
              setPropertyDetails("");
            }}
          />
        </Box>
      </Box>
      {state.addressSearchResult?.length === undefined && (
        <Typography color="error" sx={{ marginBottom: "15px" }}>
          No results found. Please try another address.
        </Typography>
      )}
      <Box sx={{ margin: "15px 0" }}>
        <Autocomplete
          size="small"
          open={isAutocompleteOpen}
          onOpen={() => setIsAutocompleteOpen(true)}
          onClose={() => setIsAutocompleteOpen(false)}
          disabled={state.addressSearchResult?.length === 0}
          options={state.addressSearchResult || []}
          getOptionLabel={(option) => option.address}
          onChange={handleAddressChange}
          renderOption={(props, option) => {
            const { key, ...restProps } = props;

            return (
              <li key={key} {...restProps}>
                <Box sx={{ display: "flex", flexDirection: "column", p: 1 }}>
                  <Typography variant="body1" fontWeight="bold">
                    {option.address}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Lot: {option.lotOnPlan}, Owner: {option.ownerName}, Ref:{" "}
                    {option.reference}
                  </Typography>
                </Box>
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select Address" disabled />
          )}
        />
      </Box>
    </>
  );

  const Agent = () => (
    <>
      <Typography variant="h6" my={2}>
        Choose an Agent
      </Typography>
      <Box sx={{ display: "flex", margin: "15px 0" }}>
        <Autocomplete
          disablePortal
          id="agent"
          fullWidth
          size="small"
          value={selectedAgent}
          onChange={handleAgentChange}
          options={state.users.map<User>((user) => ({
            id: user.sub,
            firstName: user.participant.firstName!,
            lastName: user.participant.lastName!,
          }))}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          isOptionEqualToValue={(option, value) => option.id === value.id} // Add this line
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Agent"
              error={!!agentError}
              helperText={agentError}
            />
          )}
        />
      </Box>
    </>
  );

  const Review = () => (
    <>
      <Typography variant="h6" my={2}>
        Review and Create Workspace
      </Typography>
      {propertyDetails && (
        <Box>
          <Typography variant={"h6"} my={2}>
            {propertyDetails.address}{" "}
            <Chip
              sx={{ marginLeft: 1 }}
              label={propertyDetails.status}
              color={
                propertyDetails.status === "Available" ? "success" : "error"
              }
            />
          </Typography>
          <Typography>
            <strong>Agent:</strong> {selectedAgent?.firstName}{" "}
            {selectedAgent?.lastName}
          </Typography>
          <Typography>
            <strong>Reference:</strong> {propertyDetails.reference}
          </Typography>
          <Typography>
            <strong>Lot on Plan:</strong> {propertyDetails.lotOnPlan}
          </Typography>
          <Typography>
            <strong>Description:</strong> {propertyDetails.description}
          </Typography>
          <Typography>
            <strong>Owner Name:</strong> {propertyDetails.ownerName}
          </Typography>
        </Box>
      )}
    </>
  );

  const onCreateWorkspace = () => {
    if (selectedAddress && selectedAgent) {
      const addressItem = state.addressSearchResult?.find(
        (m) => m.address === selectedAddress
      );
      if (addressItem) {
        dispatch(
          createWorkspace({
            agentId: selectedAgent.id,
            titleReference: addressItem.reference,
          })
        );
        setSelectedAddress("");
        setPropertyDetails(null);
        setSelectedAgent(null);
      }
    }
  };

  return (
    <Grid container>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};

            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <React.Fragment>
          {activeStep === 0 && Property()}
          {activeStep === 1 && Agent()}
          {activeStep === 2 && Review()}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <LCButton
              label="Back"
              onClick={handleBack}
              color="black"
              disabled={activeStep === 0}
            />
            <Box sx={{ flex: "1 1 auto", width: 1 }} />
            <LCButton
              label={activeStep === steps.length - 1 ? "Create" : "Next"}
              onClick={
                activeStep === steps.length - 1 ? onCreateWorkspace : handleNext
              }
              color="#8F1219"
              disabled={!propertyDetails}
            />
          </Box>
        </React.Fragment>
      </Box>
    </Grid>
  );
};

export default CreateWorkspacePage;
